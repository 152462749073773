/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FLXDetailsComponent } from '../../views/details-view/details-view.component';
import { environment } from 'apps/studio/src/environments/environment';
import { FLXListViewComponent } from '../../views/list-view/list-view.component';
import { getViewData, ViewSet_0_0_2, getCurrentUser, getActiveViewData } from '@flexus/core';
import { from, Observable, zip, map, switchMap, take, filter  } from 'rxjs';
import { CollapseActionPanel, FLXActionPanelComponent, setActionPanelItems } from '../../../../app-shell-features';
import { PggWorkflowShellComponent } from '../../base-components/pgg-workflow-shell/pgg-workflow-shell.component';
import { FLXHeaderActionsComponent } from '../../../../app-shell-features/header-actions/header-actions.component';

import * as itemTwoContextMenuItems from './item-two-context-menu-items';
import * as itemOneContextMenuItems from './item-one-context-menu-items';
import { Store } from '@ngrx/store';

export const ClaimWorkflow: ViewSet_0_0_2 = {
	id: 'Workflow',
	itemType: 'view_set',
	baseComponent: PggWorkflowShellComponent,
	name: 'Workflow',
	onStateInit: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: 'Workflow',
		// actionsComponent: WorkflowActionsComponent,
		controls: (instance: FLXHeaderActionsComponent, store: Store<any>) => {
			return () => {
				return zip(store.select(getCurrentUser).pipe(filter(user => !!user)), instance.controller.select(getActiveViewData).pipe(take(1))).pipe(
					map(([res, data]: any) => {
						const createRoles = [34];
						instance.currentView = data?.key;
						const controls = [
							// {
							//   id: 1,
							//   icon: 'refresh',
							//   instruction: 'Refresh',
							//   command: instance.refresh.bind(instance)
							// },

							// {
							// 	id: 3,
							// 	icon: data?.params ? 'csv-generate' : 'csv-generate',
							// 	command: instance.bulkPayments.bind(instance),
							// 	children: [
							// 		{
							// 			text: 'Generate CSV from bulk payments'
							// 		}
							// 	]
							// },
							{
								id: 5,
								icon: data?.params.reverse ? 'sorting-up' : 'sorting-down',
								instruction: data?.params.reverse ? 'Oldest to Newest' : 'Newest to Oldest',
								command: instance.reOrder.bind(instance)
							},
							{
								id: 4,
								type: 'dropdown',
								children: [
									{
										text: 'Details View',
										command: instance.loadDetailsView.bind(instance)
									},
									{
										text: 'List View',
										command: instance.loadListView.bind(instance)
									}
								]
							}
						];
						if (res.user && res.user.roles.some((role: any) => createRoles.includes(role))) {
							console.log('USER', res?.user);
							controls.unshift({
								id: 3,
								icon: 'csv-generate',
								instruction: 'Create EXCEL document',
								command: instance.bulkPayments.bind(instance)
							});
						}
						if (res.user.is_team_leader) {
							controls.map(value => {
								if (value.id === 4) {
									value.children.push({
										text: 'Team View',
										command: instance.loadTeamsView.bind(instance)
									});
								}
							});
						}

						return controls;
					})
				);
			};
		}
	},
	footer: {
		type: 'pagination'
	},
	actionPanel: (instance: FLXActionPanelComponent) => setActionPanelItems(instance, ['search', 'filter', 'bucket', 'recent-activity']),
	views: {
		default: (http, controller) => {
			return {
				id: 'default',
				dataSource: (): Observable<any> => {
					const input$ = http.get(`${environment.api_url}v1/staff_action/get_summary/`).pipe(map((data: any) => data?.payload));
					return input$;
					// return fromWorker<any, any>(() => new Worker('../../workers/test.worker', { type: 'module' }), input$).pipe(take(1));
				},
				storeBinding: controller.select(getViewData).pipe(
					map((data: any) => {
						if (data) {
							return data?.default;
						}
					})
				),
				template: FLXDetailsComponent
			};
		},
		defaultOffline: (http, controller, indexedDbService, offlineService) => {
			return {
				id: 'defaultOffline',
				dataSource: () => {
					return offlineService.getAllClaims().pipe(
						map((data: any) => data?.payload),
						take(1),
						map((items: any[]) => items.filter(item => !item?.tempKey)),
						switchMap((items: any[]) => {
							return from(indexedDbService.claimInDraft.toArray()).pipe(
								filter(Boolean),
								take(1),
								map((localDrafts: any[]) => [...localDrafts, ...items])
							);
						})
					);
				},
				storeBinding: controller.select(getViewData).pipe(map((data: any) => data?.defaultOffline)),
				template: FLXDetailsComponent
			};
		},
		alt: (http, controller) => {
			return {
				id: 'alt',
				dataSource: (): Observable<any> => {
					const input$ = http.get(`${environment.api_url}v1/staff_action/get_summary/`).pipe(map((data: any) => data?.payload));
					return input$;
				},
				storeBinding: controller.select(getViewData).pipe(
					map((data: any) => {
						if (data) {
							return data?.alt;
						}
					})
				),
				template: FLXListViewComponent
			};
		},
		team: (http, controller) => {
			return {
				id: 'team',
				dataSource: (): Observable<any> => {
					const input$ = http.get(`${environment.api_url}v1/staff_action/get_summary_team/`).pipe(map((data: any) => data?.payload));
					return input$;
				},
				storeBinding: controller.select(getViewData).pipe(
					map((data: any) => {
						if (data) {
							return data?.team;
						}
					})
				),
				template: FLXDetailsComponent
			};
		}
		// reversed: (http, controller, indexedDbService, reverse, offlineService) =>
		//   claimWorkflowViews.default(http, controller, indexedDbService, true, offlineService)
	},
	useMockContextData: false,
	mockContextData: {},
	contextMenu: {
		itemOne: {
			...itemOneContextMenuItems
		},
		itemTwo: {
			...itemTwoContextMenuItems
		}
	}
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const claimWorkflowViews = ClaimWorkflow.views;
