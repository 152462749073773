/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getFullItemOne, getSelectedItemOne, getData, SetNextNode, BigFormService, ModalService, ManifestController } from '@flexus/core';
import { combineLatest, filter, map, switchMap, take, tap } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { UntypedFormControl } from '@angular/forms';
import { getErrors } from '@flexus/error-handler';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';

export const PGG_CANCEL_CLAIM: Flow_0_0_2 = {
	fetchLevel1And2: true,
	id: 'CancelClaim',
	itemType: 'flow',
	name: 'Cancel Installation',
	excludeForOnlyStates: [169],
	excludeForOnlyRoles: [2, 18],
	header: {
		title: (_store: Store) => {
			return _store.select(getFullItemOne).pipe(
				map((itemOne: any) => {
					if (itemOne) {
						return `Cancel Claim : ${itemOne?.loan_information?.mavenclaimnumber} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Cancel Claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'CancelClaim',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: () => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		CancelClaim: {
			serverCalls: {
				cancelclaimdata: {
					errorMessage: 'No claim data returned',
					directCall: (_http: HttpClient, _store: Store) => {
						return combineLatest([
							_store.select(getFullItemOne).pipe(
								filter(Boolean),
								take(1)
							),
							_store.pipe(take(1))
						]).pipe(
							map(([claimjob, stor]: any) => {
								return { claimjob, stor };
							})
						);
					}
				}
			},
			component: 'FLXCancelClaimComponent',
			inputs: {
				subtitle: 'Do you want to permanently remove the installation'
			},
			mockContextData: {},
			checkValidityForFields: [],
			navs: [
				{
					text: 'Remove this installation',
					nextNode: 'CancelClaimReason',
					color: 'secondary',
					linkType: 'submit'
				}
			]
		},
		CancelClaimReason: {
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				containerWidth: '50vw',
				heading: '',
				subtitle: 'Do you want to permanently remove the claim?',
				formControls: {
					0: {
						label: 'Select the reason for cancellation',
						inputType: 'select',
						selectConfig: {
							displayOptions: { displayKey: 'display', valueKey: 'value' },
							itemsOption: [
								{ display: 'Cancel', value: 'Cancel' },
								{ display: 'Cancel - Client Request', value: 'Cancel - Client Request' },
								{ display: 'Duplicate', value: 'Duplicate' },
								{ display: 'Other', value: 'Other' }
							],
							placeholder: 'Reason for cancellation'
						},
						formControlName: 'cancelclaim_reason'
					}
				}
			},
			navs: [
				{
					text: 'Continue without refund',
					linkType: 'portal',
					color: 'secondary',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.heading = 'Continue without refund?';
							instance.type = 'warning';
							instance.setMessage(['Are you sure you would like to cancel the installation without refunding the customer?']);
							instance.navButtons = [
								{
									text: 'Back',
									linkType: 'close'
								},
								{
									text: 'Cancel without refund',
									color: 'alert',
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										cancelResponse: {
											errorMessage: 'Installation was not cancelled!',
											directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService, _cntr: any, modal: ModalService) => {
												return _store.select(getSelectedItemOne).pipe(
													filter(Boolean),
													take(1),
													switchMap((claim: any) => {
														const claim_id = claim?.id;
														const reason: any = _bf.bigForm.get('cancelclaim_reason')?.value;

														return _http
															.post(`${environment.api_url}v1/claim_action/cancel_claim/`, {
																claim_id,
																reason
															})
															.pipe(
																filter(Boolean),
																tap((value: any) => {
																	if (!value['success'])
																		modal.openModalDirectly(instance => {
																			instance.type = 'warning';
																			instance.message = value['reason'];
																			instance.navButtons = [
																				{
																					text: 'Back to workflow',
																					clickHandler: event => {
																						instance.router.navigate(['/workflow']);
																					},
																					linkType: 'close',
																					color: 'alert'
																				}
																			];
																		});
																})
															);
													})
												);
											}
										}
									}
								}
							];
						}
					}
				},
				{
					text: 'Continue with refund',
					color: 'secondary',
					nextNode: 'Decision',
					linkType: 'submit',
					serverFirst: true,
					serverCalls: {
						cancelResponse: {
							errorMessage: 'Claim was not removed',
							directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService, cntr: any, _modal: ModalService) => {
								return _store.select(getSelectedItemOne).pipe(
									filter(Boolean),
									take(1),
									switchMap((claim: any) => {
										const claim_id = claim?.id;
										const reason: any = _bf.bigForm.get('cancelclaim_reason')?.value;

										return _http
											.post(`${environment.api_url}v1/claim_action/cancel_claim/`, {
												claim_id: claim_id,
												reason: reason
											})
											.pipe(
												filter(Boolean),
												take(1),
												tap((value: any) => {
													if (!value['success'])
														_modal.openModalDirectly(instance => {
															instance.type = 'warning';
															instance.message = value['reason'];
															instance.navButtons = [
																{
																	text: 'Back to workflow',
																	clickHandler: event => {
																		instance.router.navigate(['/workflow']);
																	},
																	linkType: 'close',
																	color: 'alert'
																}
															];
														});
												})
											);
									})
								);
							}
						}
					}
				}
			],
			initFormFields: (_bf: BigFormService) => {
				_bf.addControl('claim_id', new UntypedFormControl(null));
				_bf.addControl('cancelclaim_reason', new UntypedFormControl(null));
			}
		},

		Decision: {
			nodeType: 'decision',
			decisions: {
				isCancelSuccess: (navs, _store: Store, modal, controller: ManifestController<any>) => {
					

					const errorSub = _store
						.select(getErrors)
						.pipe(
							filter(Boolean),
							take(1),
							map((data: any) => data.cancelResponse)
						)
						.subscribe(_ => {
							controller.dispatch(new SetNextNode('CancelClaim'));
							if (successSub) {
								successSub.unsubscribe();
							}
						});

					const successSub = _store
						.select(getData)
						.pipe(
							filter(Boolean),
							take(1),
							map((data: any) => data.cancelResponse)
						)
						.subscribe(_ => {
							controller.dispatch(new SetNextNode('SubmissionSuccess'));
							if (errorSub) {
								errorSub.unsubscribe();
							}
						});
				}
			},
			navs: []
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		claim_id: [
			(cid, storeObj) => {
				cid = storeObj['selectedContext'].selectedItemOne?.id;
				return cid;
			},
			'claim_id'
		],
		reason: 'Cancel'
	}
};
