/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigFormService, getSelectedItem, getSelectedItemOne, MakeServerCall, NodePoint_0_0_2 } from '@flexus/core';
import { take, map, filter, switchMap, mergeMap, pluck, tap } from 'rxjs/operators';
import gql from 'graphql-tag';
import { environment } from 'apps/studio/src/environments/environment';
import { iif, of } from 'rxjs';

import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';

export const PGG_BANK_DETAILS: NodePoint_0_0_2 = {
	component: 'PggBankDetailsTemplateComponent',
	hideTabItem: true,
	initFormFields: (bf, item, ins) => {
		bf.bigForm.addControl('bank_details', ins.bankDetailsForm);
	},
	serverCalls: {
		bankAccountDetails: {
			errorMessage: `Couldn't retrieve bank details`,
			directCall: (_http: HttpClient, _store: Store) => {
				return _store
					.select(getSelectedItemOne)
					.pipe(
						filter(Boolean),	
						take(1),
						mergeMap((selectedItem: any) => {
							const claim = selectedItem;
							const { id } = claim;
							return _http.get(`${environment.api_url}v1/refund_action/get_bank_details/	`, {
								params: {
									application_id: id
								}
							});
						})
					)
					.pipe(map((data: any) => data['payload']));
			}
		},
		accountTypes: {
			errorMessage: 'Could not get account types!',
			directCall: (http: any, store: any, sq: any) => {
				return sq
					.queryObject(
						gql`
							{
								allInfo {
									account_type
								}
							}
						`,
						store
					)
					.pipe(
						filter((x: any) => !!x),
						// map(values),
						// map<any, any>(flatten),
						take(1),
						switchMap((data: any) => {
							const returned_types_array: any[] = data['account_type'];
							const accept_type_arr = ['cheque', 'savings'];
							const newarray: any[] = [];
							let obj_topush;
							for (let i = 0; i <= returned_types_array.length - 1; i++) {
								for (let j = 0; j < accept_type_arr.length; j++) {
									if (returned_types_array[i].description.toLowerCase() === accept_type_arr[j]) {
										obj_topush = {
											id: returned_types_array[i].id,
											description: returned_types_array[i].description
										};
										newarray.push(obj_topush);
									}
								}
							}
							newarray.push({ id: 3, description: 'Current' });
							return of(newarray);
						})
					);
			}
		},
		bankNames: {
			errorMessage: 'Could not get banknames',
			directCall: (_http: HttpClient) => {
				return _http.get(`${environment.api_url}v1/bank_action/list_banks`);
			}
		}
	},
	// inputs: {
	// 	manualAvs: false,
	// 	branchFlag: true,
	// 	avsSuccess: AVSResultFlag.not_tested,
	// 	deflectErrors: [{ match: 'esb failure', message: 'ESB FAILURE: Maven Error. Cannot perform AVS check.' }],
	// 	errorFieldMapper: {
	// 		account_holder_name: ['Error Account Surname does not match the one supplied', 'Account surname cannot be empty.'],
	// 		initials: 'Error Account Initials do not match the ones supplied ',
	// 		acc_type: 'Error Account Type does not match the one supplied',
	// 		identification_number: ['Error Account Identity number does not match the one supplied', 'Registration/Identity number cannot be empty.'],
	// 		branch: ['Branch code cannot be empty.']
	// 		// :'Error Account Phone number does not match the one supplied' ,
	// 		// :'Error Account Email number does not match the one supplied',
	// 	},
	// 	avsByPassList: [
	// 		'Error Account Surname does not match the one supplied',
	// 		'Error Account Initials do not match the ones supplied',
	// 		'Error Account Type does not match the one supplied',
	// 		'Error Account Phone number does not match the one supplied',
	// 		'Error Account Email number does not match the one supplied'
	// 	],
	// 	avsIgnoreList: ['Error Account Phone number does not match the one supplied', 'Error Account Email number does not match the one supplied'],
	// 	branchLookupEndpoint: {
	// 		dataKey: 'branchLookup',
	// 		serviceVariable: 'buiService',
	// 		functionName: 'getBranchLookupResults',
	// 		responseSlice: 'payload',
	// 		errorMessage: 'No branches found!'
	// 	},
	// 	modalNavSuccess: {
	// 		linkType: 'submitThenNext',
	// 		serverCalls: {
	// 			saveBankDetails: {
	// 				serviceVariable: 'buiService',
	// 				functionName: 'updateJob',
	// 				errorMessage: 'Job could not be updated!!'
	// 			}
	// 		},
	// 		nextNode: 'PREVIOUS',
	// 		text: 'Continue',
	// 		color: 'primary'
	// 	}
	// },
	checkValidityForFields: ['account_holder_name', 'acc_type', 'branch_code', 'account_number', 'bank_name'],

	inputs: {
		// bankAccountDetails$: 'bankAccountDetails',
		bankCodeLookupEvent: (bankId: string, store: Store, bf: BigFormService) => {
			store.dispatch(
				new MakeServerCall({
					directCall: http => {
						return http.get(`${environment.api_url}v1/branchcodes/${bankId}`).pipe(
							filter(Boolean),
							take(1),
							pluck('payload'),
							mergeMap(payload => iif(() => Array.isArray(payload) && payload.length !== 0, of(payload[0].code), of(''))),
							tap(code => {
								// debugger;
								const bankCodeControl = bf.bigForm.get('bank_details')?.get('branch_code');
								if (bankCodeControl) {
									bankCodeControl.setValue(code);
								}
							})
						);
					},
					dataKey: 'bankCode',
					errorMessage: 'No bank code found'
				})
			);
		}
	},

	navs: [
		{
			text: 'submit',
			optIntoValidation: true,
			nextNode: 'SubmissionSuccess',
			color: 'primary',
			serverCalls: {
				response: {
					errorMessage: `Couldn't update job`,
					directCall: (http, store) => {
						// const new_state = bf.getControl('new_state').value;
						return store
							.select(getSelectedItem)
							.pipe(
								filter(Boolean),
								take(1),
								map((response: any) => response?.id)
							)
							.pipe(
								switchMap((jobid: any) => {
									const new_state = 269;
									return http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: jobid, new_state: new_state });
								})
							);
					}
				}
			}
		}
	]
};
