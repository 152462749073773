/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @nx/enforce-module-boundaries */
import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, Renderer2 } from '@angular/core';
import { Subscription, Observable, from, empty, combineLatest, of, map, switchMap, take, skipWhile, tap, share, delay, filter  } from 'rxjs';
import { Store } from '@ngrx/store';

import {
	BigFormService,
	getActiveNode,
	MakeServerCall,
	getActiveManifestItem,
	SetNextNodeUsingTab,
	getLoadingState,
	WriteDataToStore,
	NavService,
	IndexedDbService,
	ModalService,
	ManifestController,
	NetworkService
} from '@flexus/core';
import { TabsData } from '@flexus/ui-elements';
import { screenMapper as screens } from '@flexus/screens';
import { uiCompMapper } from '@flexus/ui-elements';
import * as compositeMapper from '@flexus/ui-composites';
import { AppMenuOverlayService } from '../../../../app-shell-features/app-menu/app-menu.service';
import { BillingComponent } from '@flexus/billing';
import { DecisionNodeComponent, DynamicLoaderComponent, SubFlowNodeComponent, TemplateComponent } from '@flexus/engine';

@Component({
	selector: 'pgg-flow',
	templateUrl: './pgg-flow.component.html',
	styleUrls: ['./pgg-flow.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PggFlowComponent implements OnInit, OnDestroy {
	showTabs = false;
	tabNavigation;
	selectedTab: any = null;
	activeNodeSubscription: Subscription;
	activeManifestSubscription: Subscription;
	activeManifestToRerunSub: Subscription;
	title$: Observable<string>;
	loading$: Observable<Boolean>;
	concurrency: any;
	bigFormSubscription: Subscription;
	formRulesSubscription: Subscription;
	listenToEventsSub: Subscription;
	eventsSubscriptions: Subscription[] = [];
	dynamicTabs: TabsData[];
	currentTab$: Observable<any>;
	valuesToFormSubscription: Subscription;
	manifestItem;
	hasLoader = true;

	screenMapper = { ...screens, BillingComponent };

	private _lastInitialisedManifestItemId: string;

	@ViewChild(DynamicLoaderComponent, { static: true }) host: DynamicLoaderComponent;
	constructor(
		public store: Store<any>,
		private controller: ManifestController<any>,
		public bf: BigFormService,
		public renderer: Renderer2,
		public navService: NavService,
		public modalService: ModalService,
		public indexedDbService: IndexedDbService,
		public networkService: NetworkService,
		public appMenuOverlayService: AppMenuOverlayService
	) {}

	ngOnInit() {
		this.activeManifestSubscription = this.controller
			.select(getActiveManifestItem)
			.pipe(filter(manifestItem => manifestItem && manifestItem.id !== this._lastInitialisedManifestItemId))
			.subscribe(manifestItem => {
				this._lastInitialisedManifestItemId = manifestItem.id;
				this.setTabNavigation();
				this.keepTrackOfTab();
				this.bigFormToStoreSync();
				this.checkConcurrencyOrLockItem();
				this.listenToEvents();
			});

		this.loading$ = this.store.select(getLoadingState).pipe(delay(0));
	}

	get getActiveManifestItem() {
		return this.controller.select(getActiveManifestItem).pipe(take(1), share());
	}

	private checkConcurrencyOrLockItem() {
		// Lock selected Item (hit lock endpoint, add user message for all other users)
		// When an item is locked, on select, load a concurrency component with message
		if (this.activeNodeSubscription) {
			this.activeNodeSubscription.unsubscribe();
		}
		this.activeNodeSubscription = this.getActiveManifestItem
			.pipe(
				tap(() => {
					if (this.valuesToFormSubscription) this.valuesToFormSubscription.unsubscribe();
				}),
				switchMap(flow => {
					this.manifestItem = flow;
					if (this.manifestItem?.dontLoadNodes) {
						return empty();
					} else {
						if (this.manifestItem?.onStateInit) {
							this.manifestItem.onStateInit(this);
						}
						this.valuesToFormSubscription = flow.valuesToBigForm && flow.valuesToBigForm(this.bf, this.store);
						if (flow?.concurrency) {
							const {
								concurrency: { serviceVariable, functionName, userMessage }
							} = flow;
							this.concurrency = { serviceVariable, functionName, userMessage };
							return this[serviceVariable][functionName]().pipe(
								switchMap((res: any) => {
									const hasNowBeenLocked = res?.locked === true;
									// ON SERVER: if unlocked, set locked: true; And proceed
									if (hasNowBeenLocked) {
										return this.loadNodeComponent();
									} else {
										return from(new Promise(() => this.host.loadWithInputs('UserMessageComponent', { userMessage }, {})));
									}
								})
							);
						} else {
							return this.loadNodeComponent();
						}
					}
				})
			)
			.subscribe();
	}

	private loadNodeComponent(): Observable<any> {
		return this.controller.select(getActiveNode).pipe(
			map(node => {
				if (node) {
					if (this.appMenuOverlayService.appMenuRef) {
						this.appMenuOverlayService.appMenuRef.close();
					}
					const { inputs, navs, nodeType, showTabs, permissions, hasLoader } = node;
					this.showTabs = showTabs ? showTabs : false;
					this.hasLoader = hasLoader === null || hasLoader === undefined ? true : hasLoader;
					const comp = node['component'];
					if (!!comp && typeof comp === 'string') {
						switch (nodeType) {
							case 'decision':
								this.host.loadWithInputs(DecisionNodeComponent, {}, node);
								break;
							case 'singleView':
								this.host.loadWithInputs(this.screenMapper[comp], { ...inputs, navs, permissions }, node);
								break;
							case 'subflow':
								this.host.loadWithInputs(SubFlowNodeComponent, { ...inputs, navs, permissions }, node);
								break;
							default:
								this.host.loadWithInputs(this.screenMapper[comp], { ...inputs, navs, permissions }, node);
								break;
						}
					} else if (nodeType === 'decision') {
						this.host.loadWithInputs(DecisionNodeComponent, {}, node);
					} else {
						this.host.loadWithInputs(
							TemplateComponent,
							{
								nodeInputs: inputs,
								navs,
								permissions,
								templateDefinition: comp,
								mapper: { ...this.screenMapper, ...uiCompMapper, ...compositeMapper }
							},
							node
						);
					}
				}
			})
		);
	}

	unlockItem() {
		const { serviceVariable, functionName } = this.concurrency;
		this[serviceVariable][functionName](); //NB item is used in the Service
		// then unlock the item
	}

	keepTrackOfTab() {
		this.currentTab$ = this.controller.select(getActiveNode).pipe(
			skipWhile(x => !x || x === null),
			map(data => data?.id)
		);
	}

	selectTab(tab) {
		this.selectedTab = tab;
		this.controller.dispatch(new SetNextNodeUsingTab(tab?.target));
	}

	private setTabNavigation() {
		this.getActiveManifestItem
			.pipe(
				filter(Boolean),
				map(flow => flow?.['nodes']),
				map(nodes =>
					nodes
						? Object.entries(nodes).map(([key, value]) => ({
								targetId: key,
								display: value['name'],
								show: value['hideTabItem'] === undefined || value['hideTabItem'] === false
						  }))
						: []
				)
			)
			.subscribe(tabNavigation => {
				this.dynamicTabs = tabNavigation;
			});
	}

	listenToEvents() {
		if (this.listenToEventsSub) {
			this.listenToEventsSub.unsubscribe();
		}
		this.listenToEventsSub = this.getActiveManifestItem.pipe(map(data => data?.['events'])).subscribe(events => {
			if (events) {
				console.log('eventing...');
				Object.values(events)?.forEach((ev: any) => {
					if (typeof ev.triggerOn === 'string') {
						this.eventsSubscriptions.push(
							this.bf
								.getControl(ev.triggerOn)
								?.valueChanges?.pipe(map(ev.triggerWhen))
								.subscribe(shouldFire => {
									if (shouldFire) {
										ev.dataMutations(this.bf);
										if (ev.serverCalls) {
											Object.entries(ev.serverCalls)?.forEach(([dataKey, call]: any) => {
												this.store.dispatch(new MakeServerCall({ dataKey, ...call }));
											});
										}
									}
								})
						);
					} else if (Array.isArray(ev.triggerOn)) {
						//
						this.eventsSubscriptions.push(
							combineLatest([
								...ev.triggerOn.map((trg: string) => {
									if (trg.startsWith('sys.')) {
										return this.getSystemEvent(trg);
									} else {
										return this.bf.getControl(trg)?.valueChanges?.pipe(delay(1000));
									}
								})
							])
								.pipe(map(params => ev.triggerWhen(...params)))
								.subscribe(shouldFire => {
									if (shouldFire) {
										ev.dataMutations(this.bf);
										if (ev.serverCalls) {
											Object.entries(ev.serverCalls)?.forEach(([dataKey, call]: any) => {
												this.store.dispatch(new MakeServerCall({ dataKey, ...call }));
											});
										}
									}
								})
						);
					}
				});
			}
		});
	}

	getSystemEvent(trigger: string) {
		const name = trigger?.split('.')[1];
		switch (name) {
			case 'online': {
				return this.networkService.isOnline;
			}
			default:
				return of('');
		}
	}

	// runFormRules() {
	//   this.formRulesSubscription = this.store
	//     .select(getActiveManifestItem)
	//     .pipe(
	//       map((stateFlow) => stateFlow && stateFlow['formRules']),
	//       switchMap((rules) => {
	//         if (rules) {
	//           return this.bf.executeFormRules(rules);
	//         } else {
	//           return empty();
	//         }
	//       }),
	//     )
	//     .subscribe(() => {
	//       // console.log({ ErrorMessages: this.bf.errorMessages })
	//     });
	// }

	bigFormToStoreSync() {
		if (this.bigFormSubscription) {
			this.bigFormSubscription.unsubscribe();
		}
		this.bigFormSubscription = this.getActiveManifestItem
			.pipe(
				map(stateFlow => stateFlow && stateFlow['bigFormToStoreMapper']),
				switchMap(mapper => {
					if (mapper) {
						return this.bf.bigFormToStoreMapper(mapper);
					} else {
						return empty();
					}
				})
			)
			.subscribe(storeMapper => {
				this.store.dispatch(new WriteDataToStore(storeMapper));
				this.indexedDbService.currentItem.put(storeMapper, 'currentItem');
			});
	}

	ngOnDestroy() {
		// Unlock selected item for other users to access
		if (this.activeNodeSubscription) {
			this.activeNodeSubscription.unsubscribe();
		}
		if (this.activeManifestSubscription) this.activeManifestSubscription.unsubscribe();
		if (this.activeManifestToRerunSub) this.activeManifestToRerunSub.unsubscribe();
		if (this.bigFormSubscription) this.bigFormSubscription.unsubscribe();
		if (this.formRulesSubscription) this.formRulesSubscription.unsubscribe();
		if (this.listenToEventsSub) this.listenToEventsSub.unsubscribe();
		this.eventsSubscriptions.forEach(sub => {
			if (sub) sub.unsubscribe();
		});
		if (this.valuesToFormSubscription) this.valuesToFormSubscription.unsubscribe();
		// this.unlockItem();
		if (this.manifestItem && this.manifestItem.onStateDestroy) {
			this.manifestItem.onStateDestroy(this);
		}
	}
}
