/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

export const PGG_VIEW_PAYMENTS: Flow_0_0_2 = {
	id: 'ViewPayments',
	itemType: 'flow',
	name: 'View Payments',
	excludeForOnlyStates: [169],
	excludeForOnlyRoles: [18],
	header: {
		title: (_store: Store) => {
			return _store.select(getFullItemOne).pipe(
				map((itemOne: any) => {
					if (itemOne) {
						return `Payments on this claim : ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Payments on this claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ViewPayments',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: () => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		ViewPayments: {
			component: 'FLXHeadingWithInstructionsComponent',
			// component: 'ViewPaymentsComponent',
			inputs: {
				title: 'View Payments',
				instructions: ['Completion pending completion of payments micro-service', '... loading ...']
			},
			// initFormFields: () => {},
			navs: [{ text: 'cooie!', color: 'primary' }]
		},
		SubmissionSuccess: {
			component: 'FlXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {}
	// fetchLevel1And2: true,
	// serverCalls: {
	// 	// claimAppointments: {
	// 	// 	serviceVariable: 'pggService',
	// 	// 	functionName: 'getClaimAppointments',
	// 	// 	// responseSlice: 'payload',
	// 	// 	errorMessage: 'No claim appointments could be found!'
	// 	// }
	// }
};
