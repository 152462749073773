/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigFormService, Flow_0_0_2, MakeServerCall, ManifestController, ModalService, SetNextNode, getFullItemOne, getSelectedItem } from '@flexus/core';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
// import { photoNode } from '../reusable/PHOTO_NODE';
import { setActionPanelItems } from '../../../../app-shell-features';
import { take, map, EMPTY, of, switchMap, filter } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { environment } from 'apps/studio/src/environments/environment';

export const PGG_371: Flow_0_0_2 = {
	id: '371',
	name: 'workmanship_issue_fixed',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				filter(Boolean),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Workmanship Issue Fixed';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Review Fixed Workmanship Issue'
		},
		viewRoles: {
			0: 'Workmanship Fix Review'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'pggService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		
	},
	startNode: 'jobInformation',
	nodes: {
	
		jobInformation: {
			showTabs: true,
			...jobInfoNode(371),
            navs: [
                {
                    text: 'continue',
                    color: 'primary',
                    nextNode: 'reportNode'	
                }
            ]
		},
		reportNode: {
            showTabs:true,
            name: 'Report',
           
            component: {
                children: [
                    {
                        component: 'FLXHeadingWithInstructionsComponent',
                        inputs: {
							headingConfig: { weight: 'normal' },
							title: 'Report Node [to be developed]'
						}
                    }
                ]
            },
            navs: [
                {
                    text: 'continue',
                    color: 'primary',
                    nextNode: 'photoNode'	
                }
            ]
		},
		photoNode: {
            showTabs:true,
            name: 'Photos',
            component: 'FLXViewPhotosComponent',
		inputs: {
			heading: 'Job Photos',
			location: 'context-menu'
		},
            navs: [
                {
                    text: 'continue',
                    color: 'primary',
                    nextNode: 'sp_decision'	
                }
            ]
		},
		sp_decision: {
            hideTabItem: true,
            nodeType: 'decision',
            errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => EMPTY
			},
            decisions: {
                select_sp_on_job: (navs: any, _store: any, _modal: ModalService) => {
                    return _store.select(getSelectedItem).pipe(
                        filter(Boolean),
                        take(1),
                        map(() => {
                            const ORIGINAL_SP: string = 'ORIGINAL SP';
                            const assigned = ORIGINAL_SP 
                            if(assigned === 'ORIGINAL SP') {
                                _modal.openModalDirectly(instance => {
                                    instance.type = 'info';
                                    instance.heading = 'Is the customer satisfied?';
                                    instance.setMessage([`If the customer is happy, this job will now be closed.`])
                                    instance.navButtons = [
                                      {
                                        text: 'back',
                                        linkType: 'close',
                                        color: 'default',                                        clickHandler: () => {
                                            _store.dispatch(
                                              new MakeServerCall({
                                                errorMessage: '',
                                                directCall: (http: HttpClient, store: Store, sq, bf: BigFormService, controller: ManifestController<any>) => {
                                                  controller.dispatch(new SetNextNode('photoNode'));
                                                  return of({});
                                                }
                                              })
                                            );
                                          }
                                        
                                      },
                                      {
                                        text: 'ok',
                                        linkType: 'close',
                                        clickHandler: () => {
                                          _store.dispatch(
                                            new MakeServerCall({
                                              errorMessage: '',
                                              directCall: (http: HttpClient, store: Store, sq, bf: BigFormService, controller: ManifestController<any>) => {
                                                store.select(getSelectedItem).pipe(
                                                    filter(Boolean),  
                                                    take(1),
                                                    map((res: any) => res),
                                                    switchMap((res: any) => {
                                                        const requestdata = {
                                                            job_id: res.id,
                                                            new_state: 28,
                                                        }
                                                        return http.post(`${environment.api_url}v1/job_action/update_job/`, requestdata);
                                                    })
                                                ).subscribe()
                                                // use success in 'update_job' piped for err?
                                                controller.dispatch(new SetNextNode('submissionSuccess'));
                                                return of({});
                                              }
                                            })
                                          );
                                        }
                                      }
                                    ];
                                  });
                            } else if(assigned === 'NEW_SP') {
                                _modal.openModalDirectly(instance => {
                                    instance.type = 'warning';
                                    instance.heading = 'New Service Provider on Job';
                                    instance.setMessage([`The fix was completed by a different service provider than the original.
`,'A new job card will be created in order to recoup the costs.'])
                                    instance.navButtons = [
                                      {
                                        text: 'back',
                                        linkType: 'close',
                                        color: 'default',                                        clickHandler: () => {
                                            _store.dispatch(
                                              new MakeServerCall({
                                                errorMessage: '',
                                                directCall: (http: HttpClient, store: Store, sq, bf: BigFormService, controller: ManifestController<any>) => {
                                                   
                                                  controller.dispatch(new SetNextNode('photoNode'));
                                                  return of({});
                                                }
                                              })
                                            );
                                          }
                                        
                                      },
                                      {
                                        text: 'ok',
                                        linkType: 'close',
                                        clickHandler: () => {
                                          _store.dispatch(
                                            new MakeServerCall({
                                              errorMessage: '',
                                              directCall: (http: HttpClient, store: Store,sq,bf,controller: ManifestController<any> ) => {
                                                store.select(getSelectedItem).pipe(
                                                    filter(Boolean),
                                                    take(1),
                                                    map((res: any) => res),
                                                    switchMap((res: any) => {
                                                        const requestdata = {
                                                            job_id: res.id,
                                                            new_state: 384,
                                                            // job_status: 'closed'
                                                        }
                                                        return http.post(`${environment.api_url}v1/job_action/update_job/`, requestdata);

                                                        // return of(data);
                                                    })
                                                ).subscribe()
                                                controller.dispatch(new SetNextNode('submissionSuccess'));
                                                return of({});
                                              }
                                            
                                            })
                                          );
                                        },
                                        nextNode: 'submissionSuccess'	
                                      }
                                    ];
                                  });
                            }

                             
                        })
                    ).subscribe();
                }
            },
            navs:[]

        },
		
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
	}
};
