/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BigFormService, Flow_0_0_2, getFullItemOne, getFullItemTwo, getSelectedItemOne, getSelectedItemTwo } from '@flexus/core';
import { Store } from '@ngrx/store';
import { setActionPanelItems, CollapseActionPanel } from '../../../../app-shell-features';
import gql from 'graphql-tag';
import { take, map, mergeMap, switchMap, filter } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { customerQueryNode } from '../reusable/CUSTOMER_REFUND_QUERY';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { PGG_BANK_DETAILS } from './BANK_DETAILS';

export const PGG_258: Flow_0_0_2 = {
	id: '258',
	name: 'request_voucher_cancellation',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['installation-summary', 'documents']),
	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				filter(Boolean),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Voucher Cancellation Refund';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {},
	instructions: {
		editRoles: {
			0: 'Voucher cancellation request'
		},
		viewRoles: {
			0: 'Voucher cancellation request'
		}
	},
	startNode: 'VoucherCancellationNotification',
	nodes: {
		VoucherCancellationNotification: {
			...jobInfoNode(258, 'Voucher Cancellation Refund', [
				'The customer has requested a refund but has not entered in their banking details.',
				'Contact the customer to determine the current status.'
			]),
			navs: [
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'RefundBreakdown'
				}
			]
		},
		RefundBreakdown: {
			serverCalls: {
				voucherAmount: {
					errorMessage: `Couldn't get voucher amount`,
					directCall: (_htp: HttpClient, _store: Store, _sq: any) => {
						return _sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													values {
														total_value
														client_value
														sp_value
													}
												}
											}
										}
									}
								`,
								_store.select(getFullItemTwo).pipe(
									filter(Boolean),
									take(1),
									map((res: any) => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((querydata: any) => {
									const total_value: number = +querydata?.total_value;
									const client_value: number = +querydata?.client_value;
									const sp_value: number = +querydata?.sp_value;
									const valueobject: any = {
										total_value: total_value,
										client_value: client_value,
										sp_value: sp_value
									};
									console.log('QUERYDATA', valueobject);

									return valueobject;
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Refund breakdown',
							instructions: ['Please confirm expected refund amount with customer']
						}
					},
					{
						component: 'PinggoRefundBreakdownComponent',
						inputs: {
							subheading: 'Please confirm expected refund amount with customer',
							voucherAmount$: 'voucherAmount'
						}
					}
				]
			},
			navs: [
				{
					text: 'query refund',
					color: 'secondary',
					nextNode: 'CustomerQuery'
				},
				{
					text: 'continue',
					nextNode: 'BankDetailsCapture',
					color: 'primary'
				}
			]
		},
		BankDetailsCapture: {
			initFormFields: (bf: any) => {
				bf.addControl('new_state', new UntypedFormControl('', [Validators.required]));
				bf.patchValues({ new_state: 261 }); // find correct  state here
			},
			// component: 'BankDetailsComponent'
			...PGG_BANK_DETAILS,
			navs: [
				{
					text: 'submit',
					color: 'primary',
					linkType: 'submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: '',
							directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService) => {
								_bf.addControl('new_state', new UntypedFormControl(261));
								return _store.select(getSelectedItemOne).pipe(
									filter(Boolean),
									take(1),
									mergeMap((data: any) => {
										const claim = data;
										const { id } = claim;
										const formcontrol = _bf.getControl('bank_details');
										const account_holder_name = formcontrol.get('account_holder_name').value;
										const account_number = formcontrol.get('account_number').value;
										const account_type = formcontrol.get('account_type').value.toLowerCase();
										const bank_name = formcontrol.get('bank_name').value;
										return _http.post(`${environment.api_url}v1/refund_action/modify_bank_details/`, {
											application_id: id,
											account_holder: account_holder_name,
											account_type: account_type,
											bank_name: bank_name,
											account_number: account_number
										});
									})
								);
							},
							followUpSuccessCalls: {
								updateJob: {
									errorMessage: 'Could not update job!',
									directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService) => {
										return _store.select(getSelectedItemTwo).pipe(
											filter(Boolean),
											take(1),
											switchMap((job: any) => {
												const job_id = job?.id;
												const new_state = _bf.getControl('new_state').value;
												return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: new_state });
											})
										);
									}
								}
							}
						}
					}
				}
			]
		},
		CustomerQuery: {
			checkValidityForFields: ['customer_query_258'],
			initFormFields: (bf: any) => {
				bf.addControl('refund_query', new UntypedFormControl('', [Validators.required]));
				bf.patchValues({ new_state: 259 });
			},
			...customerQueryNode(258, `Customer's Query`, [], 'customer_query_258'),
			navs: [
				{
					text: 'Submit',
					linkType: 'submit',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'pggService',
							functionName: 'updateJob',
							errorMessage: `Couldn't update job!`
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		customer_query_258: [
			(refundquery: any) => {
				return refundquery;
			},
			'job_information.customer_query_258'
		]
	}
};
