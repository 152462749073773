/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { environment } from 'apps/studio/src/environments/environment';
import { iif, of, map, filter, take, pluck, switchMap  } from 'rxjs';

export const PGG_VIEW_JOB_SUMMARY: Flow_0_0_2 = {
	id: 'JobSummary',
	itemType: 'flow',
	name: 'View Job Summary',
	excludeForOnlyStates: [169],
	excludeForOnlyRoles: [18],
	header: {
		title: (store) => {
			return store.select(getFullItemTwo).pipe(
				filter(Boolean),
				map(itemTwo => {
					if (itemTwo) {
						return `Installation job  - ${itemTwo?.claim?.loan_information?.voucher_key} - ${itemTwo?.claim?.applicant?.first_name}`;
						// return `Job Summary : ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return 'Installation Job Summary';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'JobSummary',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: () => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		JobSummary: {
			component: {
				children: [
					{
						component: 'JobSummaryComponent',
						inputs: { title: 'Job Information', assessorName$: 'assessorName', teamLead$: 'teamLeader' }
					}
				]
			},
			serverCalls: {
				assessorName: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store) => {
						return store.select(getFullItemTwo).pipe(
							filter(x => !!x),
							take(1),
							pluck('assessor'),
							switchMap(assessor => {
								return iif(
									() => assessor,
									http
										.post(environment.api_url + 'v1/staff_action/get_staff_member_names', {
											ids: [assessor]
										})
										.pipe(
											map((data: any) => {
												const namesMapper = data.payload;
												return namesMapper[assessor];
											})
										),
									of('Not Assigned')
								);
							})
						);
					}
				},
				teamLeader: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store) =>
						store.select(getFullItemTwo).pipe(
							filter(Boolean),
							take(1),
							// pluck('team_leader'),
							map((data: any) => data.team_leader),
							map(teamLeader => (teamLeader && teamLeader.full_name) || 'Not Assigned')
						)
				}
			}
		}
	}
};
