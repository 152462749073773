/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getSelectedItemOne, getFullItemOne, getData } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { isNil } from '@flexus/utilities';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { of, take, filter, map, switchMap, pluck } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';

export const PGG_55: Flow_0_0_2 = {
	id: '55',
	name: 'finalise_claim',
	itemType: 'flow',
	header: {
		title: (store) => {
			return store.select(getFullItemOne).pipe(
				filter(Boolean),
				take(1),
				map((itemOne: any) => {
					if (itemOne) {
						return `Finalise Claim : ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.surname}`;
					} else {
						return 'Finalise Claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance =>
		setActionPanelItems(instance, ['notes', 'documents', 'time-stamp', 'claim-card', 'call-logs'], {
			filesFunctionName: 'getAllClaimFiles'
		}),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	instructions: {
		editRoles: {
			0: 'Finalise Claim'
		},
		viewRoles: {
			0: '--'
		}
	},
	events: {},
	fetchLevel1And2: true,
	serverCalls: {
		closeClaimSummary: {
			serviceVariable: 'silService',
			errorMessage: '',
			ignoreFalseError: true,
			functionName: 'getCloseClaimInfo'
		},
		canCloseClaim: {
			serviceVariable: 'silService',
			directCall: (http, store, sq, bf, controller, modal) => {
				return store.select(getSelectedItemOne).pipe(
						filter(Boolean),
						take(1)).pipe(
					switchMap((claim: any) => {
						const claim_id = claim?.id;
						
						const data = {
							claim_id: claim_id
						};
						return http.post(`${environment.api_url}v1/claim_action/can_finalise_claim`, data).pipe(
							filter(Boolean),
							take(1),
							map((res: { payload: any; success: boolean; reason: Array<any> }) => {
								bf.bigForm.get('maven_claim_state')?.setValue(res?.payload?.maven_claim_state);
								if (res?.success === true) {
									bf.bigForm.get('maven_claim_state')?.setValue(res?.payload?.maven_claim_state);
									bf.bigForm.get('reasons_provided')?.setValue(true);
								} else if (!res?.success) {
									modal.openModalDirectly(instance => {
										instance.type = 'warning';
										instance.message = `You can not finalise claim reason : ${res?.payload?.descriptions?.toString()}`;
										instance.navButtons = [
											{
												text: 'Close',
												linkType: 'close',
												color: 'alert'
											}
										];
									});
								}
							})
						);
					})
				);
			},
			errorMessage: 'Claim can not be closed',
			ignoreFalseError: true
		},
		claimAppointments: {
			serviceVariable: 'silService',
			functionName: 'getClaimAppointments',
			// responseSlice: 'payload',
			errorMessage: 'No claim appointments could be found!'
		},
		paymentsExist: {
			errorMessage: `Payments don't exist`,
			directCall: (http, store, sq, bf) => {
				return store
					.select(getData)
					.pipe(
						pluck('claimAppointments', 'payload', 'xml'),
						filter(Boolean),
						take(1),
						map(pay => pay as any)
					)
					.pipe(
						switchMap(pay => {
							const payments = pay.payments;
							console.log('Payments', payments);
							bf.addControl('payments_exist', new UntypedFormControl(null));
							bf.addControl('number_of_payments', new UntypedFormControl(null));
							if (isNil(payments) || Array.isArray(payments)) {
								bf.bigForm.get('payments_exist')?.setValue(false);
								bf.bigForm.get('number_of_payments')?.setValue(0);
							} else {
								bf.bigForm.get('payments_exist')?.setValue(true);
								bf.bigForm.get('number_of_payments')?.setValue(payments['item']?.length);
							}
							return of(payments);
						})
					);
			}
		}
	},
	startNode: 'CloseClaim',
	nodes: {
		CloseClaim: {
			mockContextData: {},
			checkValidityForFields: ['reasons_provided'],
			initFormFields: (bf) => {
				bf.addControl('new_state', new UntypedFormControl(7));
				bf.addControl('reasons_provided', new UntypedFormControl(false, [Validators.requiredTrue]));
				bf.addControl('maven_claim_state', new UntypedFormControl(null, [Validators.required]));
			},
			inputs: {
				title: 'Close the Claim',
				subtitle: 'Confirm that all work was done and close the claim.',
				maven_claim_stateArray: [
					{ id: -1, description: 'Undefined' },
					{ id: 0, description: 'Cancel claim' },
					{ id: 1, description: 'Cancel with excess' },
					{ id: 2, description: 'Finalise' },
					{ id: 3, description: 'Repudiate' }
				],
				reasonsArray: [
					{ id: 0, description: ' Job not paid on maven' },
					{ id: 1, description: ' Job not appointed / paid on Maven' },
					{ id: 2, description: ' No consignment invoice on Maven' },
					{ id: 3, description: ' Maven response problem' }
				]
			},
			component: 'CloseClaimComponent',
			serverCalls: {},
			navs: [
				{
					text: 'Finalise',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							ignoreFalseError: false,
							errorMessage: 'Claim could not be closed',
							// functionName: 'finaliseClaim',
							directCall: (http, store, sq, bf, cntrl, modal) => {
								return store.select(getFullItemOne).pipe(
										filter(Boolean),
										take(1),
										map(res => res as any)
									).pipe(
									switchMap(([res]) => {
										const mavenstate: number = bf.bigForm.get('maven_claim_state')?.value;
										const claim_id: any = res?.id;
										const canceledData = {
											claim_id: claim_id,
											reason: ''
										};
										const data = {
											claim_id: claim_id
										};
										const repData = {
											claim_id: claim_id,
											repudiation_type: res?.office_use?.repudiation ? res?.office_use?.repudiation?.repudiation_type : '',
											repudiation_reason: 1,
											letter_key: res?.office_use?.repudiation?.repudiation_letter,
											repudiation_sub_reason: 1,
											diagnosis: '--',
											email: res?.office_use?.repudiation ? res?.office_use?.repudiation?.emailaddress : '',
											final: 'Y',
											sendletter: 'N',
											_optionalState: 7
										};

										switch (mavenstate) {
											case 0: // Cancelled (4)
											case 1: // Cancelled - with Excess (13)
												canceledData.reason = mavenstate === 0 ? '4' : '13';
												return http.post(`${environment.api_url}v1/claim_action/cancel_claim/`, canceledData);
											case 2: // Finalise claim
												return http.post(`${environment.api_url}v1/claim_action/finalise_claim/`, data).pipe(
													filter(Boolean),
													take(1),
													map(res => {
														if (!res['success']) {
															modal.openModalDirectly(instance => {
																instance.type = 'warning';
																instance.message = res['reason'] ? res['reason'][0] : 'An error occurred could not finalise claim';
																instance.navButtons = [
																	{
																		text: 'Back to workflow',
																		clickHandler: () => {
																			instance.router.navigate(['/workflow']);
																		},
																		linkType: 'close',
																		color: 'alert'
																	}
																];
															});
														}
													})
												);
											case 3: // Finalise Repudiation
												return http.post(`${environment.api_url}v1/claim_action/finalise_repudiation/`, repData);
										}
									})
								);
							}
						}
					}
				},
				{
					text: 'View Payments',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance, store, bf) => {
							if (bf.bigForm.get('payments_exist')?.value === false) {
								(instance.type = 'info'), instance.setMessage(['There are no payments associated with this claim']);
								instance.navButtons = [
									{
										text: 'OK',
										color: 'default',
										linkType: 'close'
										// nextNode: 'CloseClaim'
									}
								];
							} else if (bf.bigForm.get('payments_exist')?.value === true) {
								instance.type = 'info';
								if (bf.bigForm.get('number_of_payments')?.value === 1) {
									instance.setMessage([bf.bigForm.get('number_of_payments')?.value + ` Payment has been made on this claim`, `Click 'View Payments' to proceed`]);
								} else {
									instance.setMessage([bf.bigForm.get('number_of_payments')?.value + ` Payments have been made on this claim`, `Click 'View Payments' to proceed`]);
								}
								instance.navButtons = [
									{
										text: 'No thanks!',
										color: 'default',
										linkType: 'close'
									},
									{
										text: 'View Payments',
										color: 'default',
										linkType: 'nextNode',
										nextNode: 'ViewPayments'
									}
								];
							}
						}
					},
					// nextNode: 'Decision',
					location: 'center',
					color: 'secondary'
				}
			]
		},
		ViewPayments: {
			serverCalls: {},
			component: 'ViewPaymentsComponent',
			initFormFields: () => {}
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		claim_id: 'claim_id',
		new_state: 'new_state'
	}
};
