/* eslint-disable @typescript-eslint/no-explicit-any */
import { UntypedFormControl, Validators } from '@angular/forms';
import { getCurrentUser, getFullItemTwo, NodePoint_0_0_2 } from '@flexus/core';
import { combineLatest, filter, take  } from 'rxjs';

export const customerQueryNode = (state?: string | number, heading?: string, instructions?: any[], formControlName?: string, formcontrollabel?: string): NodePoint_0_0_2 => {
	return {
		name: 'Customer Query',
		showTabs: false,
		hideTabItem: true,
		initFormFields: (bf, item, instance, storeQuery, store) => {
			bf.addControl('new_state', new UntypedFormControl('', [Validators.required]));
			combineLatest([store.select(getCurrentUser).pipe(filter(x => !!x, take(1))), store.select(getFullItemTwo).pipe(filter(x => !!x, take(1)))])
				.pipe(take(1))
				.subscribe(([author, job]) => {
					bf.patchValues({ author: author.user.id });
					bf.patchValues({ author_name: author.user.full_name });
					bf.patchValues({ current_state: job?.state });
				});
			bf.patchValues({ new_state: 259 }); // find correct  state here
		},
		component: {
			children: [
				{
					component: 'FLXHeadingWithInsructionsComponent',
					inputs: {
						title: heading,
						instructions: instructions
					}
				},
				{
					component: 'FLXFlatDynamicFormComponent',
					inputs: {
						heading: `Query Response`,
						subheading: 'Respond to the Customer',
						formControls: {
							0: {
								label: formcontrollabel,
								inputType: 'textarea',
								rows: 5,
								formControlName: formControlName
							}
						},
						formLayout: 'stacked',
						containerWidth: '30vw',
						headingSize: 'medium',
						// headingWeight: 'light',
						headingType: 'creation',
						headingMargin: '30px 0 75px 0'
					}
				}
			]
		}
	};
};
