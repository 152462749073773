/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { BigFormService, Flow_0_0_2, MakeServerCall, ModalService, getFullItemOne, getSelectedItem } from '@flexus/core';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
// import { photoNode } from '../reusable/PHOTO_NODE';
import { setActionPanelItems } from '../../../../app-shell-features';
import { take, map, of, switchMap, filter } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { environment } from 'apps/studio/src/environments/environment';
import { UntypedFormControl, Validators } from '@angular/forms';
import { photoNode } from '../reusable/PHOTO_NODE';

export const PGG_372: Flow_0_0_2 = {
	id: '372',
	name: 'workmanship_sp_report',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: (store: any) => {
            
			return store.select(getFullItemOne).pipe(
				filter(Boolean),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Workmanship Issue Fixed - Review';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Decide Warranty Outcome'
		},
		viewRoles: {
			0: 'Warranty Outcome Decision'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'pggService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		
	},
	startNode: 'jobInformation',
	nodes: {
	
		jobInformation: {
            initFormFields: (_bf: BigFormService) => {
                _bf.addControl('new_state', new UntypedFormControl(377, [Validators.required]));
            },
			showTabs: true,
			...jobInfoNode(372),
            navs: [
                {
                    text: 'invalid warranty',
                    color: 'secondary',
                    serverCalls: {
                        invalidate_warrannty: {
                            errorMessage: '',
                            directCall: (h, _store,sq, bf, cntrl, _modal: ModalService) => {
                                let customer_name = '';
                                let customer_number = '';
                                _store.select(getSelectedItem).pipe(
                                    filter(Boolean),
                                    take(1),
                                    map((res: any) => {
                                        customer_name = res?.claim?.applicant?.first_name;
                                        customer_number = res?.claim?.applicant?.cellnumber ? res?.claim?.applicant?.cellnumber : 'No contact number in that place';
                                    }),
                                ).subscribe()
                                _modal.openModalDirectly(instance => {
                                    instance.type = 'warning';
                                    instance.heading = 'Please notifiy the customer the claim is invalid';
                                    instance.message = 'Customer Details'
                                    instance.setMessage([`Customer Name: ${customer_name}`, `Customer Number: ${customer_number}`]);
                                    instance.navButtons = [
                                        {
                                            text: 'I have informed the customer',
                                            color: 'default',
                                            clickHandler: () => {
                                                _store.dispatch(
                                                    new MakeServerCall({
                                                      errorMessage: '',
                                                      directCall: (http: HttpClient, store: Store) => {
                                                        store.select(getSelectedItem).pipe(
                                                            filter(Boolean),
                                                            take(1),
                                                            switchMap((res: any) => {
                                                                const requestdata = {
                                                                    job_id: res.id,
                                                                    new_state: 378,
                                                                }

                                                                return http.post(`${environment.api_url}v1/job_action/update_job/`, requestdata);
                                                            })
                                                        ).subscribe()
                                                        // use success in 'update_job' piped for err?
                                                        // controller.dispatch(new SetNextNode('submissionSuccess'));
                                                        return of({});
                                                      }
                                                    })
                                                  );
                                                
                                            }
                                        }
                                    ]
                                })
                                return of({})
                            }
                        }
                    }
                },
                {
                    text: 'approve warranty',
                    color: 'primary',
                    serverCalls: {
                        updateJob: {
                            errorMessage:'could not update job',
                            serviceVariable: 'pggService',
                            functionName: 'updateJob',

                        }
                    }


                }
            ]
		},
		reportNode: {
            showTabs:true,
            name: 'Report',
           
            component: {
                children: [
                    {
                        component: 'FLXHeadingWithInstructionsComponent',
                        inputs: {
							headingConfig: { weight: 'normal' },
							title: 'Report Node [to be developed]'
						}
                    }
                ]
            },
            navs: [
                {
                    text: 'invalid warranty',
                    color: 'secondary',
                    serverCalls: {
                        invalidate_warrannty: {
                            errorMessage: '',
                            directCall: (h, _store,sq, bf, cntrl, _modal: ModalService) => {
                                let customer_name = '';
                                let customer_number = '';
                                _store.select(getSelectedItem).pipe(
                                    filter(Boolean),
                                    take(1),
                                    map((res: any) => {
                                        customer_name = res?.claim?.applicant?.first_name;
                                        customer_number = res?.claim?.applicant?.cellnumber ? res?.claim?.applicant?.cellnumber : 'No contact number in that place';
                                        console.log('RESIDUALE', res)
                                    }),
                                ).subscribe()
                                _modal.openModalDirectly(instance => {
                                    instance.type = 'warning';
                                    instance.heading = 'Please notifiy the customer the claim is invalid';
                                    instance.message = 'Customer Details'
                                    instance.setMessage([`Customer Name: ${customer_name}`, `Customer Number: ${customer_number}`]);
                                    instance.navButtons = [
                                        {
                                            text: 'I have informed the customer',
                                            color: 'default',
                                            clickHandler: () => {
                                                _store.dispatch(
                                                    new MakeServerCall({
                                                      errorMessage: '',
                                                      directCall: (http: HttpClient, store: Store) => {
                                                        store.select(getSelectedItem).pipe(
                                                            filter(Boolean),
                                                            take(1),
                                                            switchMap((res: any) => {
                                                                const requestdata = {
                                                                    job_id: res.id,
                                                                    new_state: 378,
                                                                }

                                                                return http.post(`${environment.api_url}v1/job_action/update_job/`, requestdata);
                                                            })
                                                        ).subscribe()
                                                        // use success in 'update_job' piped for err?
                                                        // controller.dispatch(new SetNextNode('submissionSuccess'));
                                                        return of({});
                                                      }
                                                    })
                                                  );
                                                
                                            }
                                        }
                                    ]
                                })
                                return of({})
                            }
                        }
                    }
                },
                {
                    text: 'approve warranty',
                    color: 'primary',
                    serverCalls: {
                        updateJob: {
                            errorMessage:'could not update job',
                            serviceVariable: 'pggService',
                            functionName: 'updateJob',

                        }
                    }


                }
            ]
		},
		photoNode: {
            showTabs:true,
            // name: 'Photos',
            ...photoNode(372),	
          
		},
		
		
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
	}
};
