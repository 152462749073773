/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { DisplayTypes } from '../../../models/display-types';

export const PGG_VIEW_TIME_STAMPS: Flow_0_0_2 = {
	id: 'ViewTimeStamps',
	itemType: 'flow',
	name: 'View Time Stamps',
	// excludeForOnlyRoles: [18],
	header: {
		title: (_store: Store) => {
			return _store.select(getFullItemOne).pipe(
				map((itemOne: any) => {
					if (itemOne) {
						return `Time Stamps on this claim : ${itemOne?.loan_information?.voucher_key}  -  ${itemOne?.applicant?.first_name}`;
					} else {
						return 'Time Stamps on this claim';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ViewTimeStamps',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: () => {
		return () => {
			return [{}];
		};
	},
	nodes: {
		ViewTimeStamps: {
			component: 'FLXTimeStampsComponent',
			inputs: {
				heading: 'Time Stamps',
				// config: { DisplayType: DisplayTypes.FullScreen, showHeading: true }
				config: { DisplayType: DisplayTypes.FullScreen }
			},
			initFormFields: () => {},
			navs: []
		}
	},
	excludeForOnlyStates: [169],
	bigFormToStoreMapper: {},
	serverCalls: {}
};
