/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getSelectedItemOne, getFullItemTwo, getFullItemOne, getSelectedItem, getAllInfo, BigFormService, getSelectedItemTwo } from '@flexus/core';
import { of, forkJoin, map, switchMap, take, filter, mergeMap  } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { findJobLog } from '../reusable/state-utils';
import { getAllInfoIndex, humaniseDate } from '@flexus/utilities';
import { KVLHeading } from '@flexus/ui-elements';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { UntypedFormControl, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import gql from 'graphql-tag';
import moment from 'moment';

export const PGG_369: Flow_0_0_2 = {
	id: '369',
	name: 'workmanship_assessment_request',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store) => {
			return store.select(getFullItemOne).pipe(
				filter(Boolean),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Workmanship Assessment Request';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	serverCalls: {
		installer_details: {
			errorMessage: 'could not get the pinggo installer details',
			directCall: (http: any, store: any) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						filter(Boolean),
						take(1),
						map((leader: any) => {
							let installername: string = '';
							let installernumber: string = '';
							if (leader?.team_leader === null) {
								installername = 'no team leader detail';
								installernumber = 'no contact number provided';
							}
							const installer_onsite: string = 'Yes';
							const installer_complete: string = 'Yes';
							const installerobj = {
								'Installer name': `${installername}`,
								'Installer Contact Number': `${installernumber}`,
								'Installer was on site': `${installer_onsite}`,
								'Installer completed installation': `${installer_complete}`
							};
							const list: any[] = [];
							list.push(installerobj);
							return list;
						}))
					
			}
		},
		customer_details: {
			errorMessage: `Could not get important information`,
			directCall: (http: any, store: any, sq: any) => {
				return sq
					.queryObject(
						gql`
							{
								fullItemTwo {
									claim {
										applicant {
											first_name
											contact_number
										}
									}
								}
							}
						`,
						store.select(getFullItemTwo).pipe(
							filter(Boolean),
							take(1),
							map((res: any) => ({ fullItemTwo: res }))
						)
					)
					.pipe(
						map((querydata: any) => {
							const name: string = querydata?.first_name;
							const number: string = querydata?.contact_number;
							const list: any[] = [];
							const contactinfo = {
								'Customer name': `${name}`,
								'Customer Contact Number': `${number}`
							};
							list.push(contactinfo);
							return list;
						})
					);
			}
		},
		// ...PGG_261.serverCalls,

		timeStamps: {
			errorMessage: 'Could not get time stamps',
			directCall: (http, store) => {
				return forkJoin([
					store.select(getSelectedItemOne).pipe(
						filter(Boolean),
						take(1)
					),
					store.select(getFullItemTwo).pipe(
						filter(Boolean),
						take(1)
					)
				]).pipe(
					filter(Boolean),
					switchMap(([claim, job]: any) => {
						return http.post(`${environment.api_url}v1/claim_action/get_time_logs/`, { [`claim_id`]: claim?.id }).pipe(
							filter(Boolean),
							switchMap((res: any) => {
								console.log('TIME STAMPS JOB RESULT', res);
								const setsOfJobLogs: any[] = res?.payload['jobs'];
								const autoPayJobLogForState27Or39 = findJobLog(setsOfJobLogs, job, [27, 39]);
								const applicationCreatorId = claim?.application_creator?.id;
								const paymentapproval1 = job?.job_information?.paymentapproval1;
								return http
									.post(environment.api_url + 'v1/staff_action/get_staff_member_names', {
										ids: [+applicationCreatorId, +paymentapproval1]
									})
									.pipe(
										map((data: any) => data?.payload),
										map((mapper: any) => ({ payArroval1: autoPayJobLogForState27Or39?.modifier || mapper[paymentapproval1], payHandler: mapper[applicationCreatorId] }))
									);
							})
						);
					})
				);
			}
		},
		customer_query: {
			errorMessage: 'no query',
			directCall: () => {
				return of({});
			}
		}
	},

	instructions: {
		editRoles: {
			0: 'Reopen Claim'
		},
		viewRoles: {
			0: 'Reopen Claim'
		}
	},

	startNode: 'ReopenClaim',

	nodes: {
		ReopenClaim: {
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Summary'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'customer_details',
							colouredHeading: new KVLHeading('Customer Details', 'secondary')
						}
					},
					
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'installation_summary',
							colouredHeading: new KVLHeading('Installation Summary', 'secondary')
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'installer_details',
							colouredHeading: new KVLHeading('Installer Details', 'secondary')
						}
					},
					// {
					// 	component: 'FLXKeyValueListComponent',
					// 	inputs: {
					// 		data$: 'timeStamps',
					// 		colouredHeading: new KVLHeading(`Customer's Query`, 'secondary')
					// 	}
					// }
				]
			},
			navs: [
				{
					text: 'continue',
					nextNode: 'RecordReinstatementReason',
					color: 'primary',
					// location: 'center'
				},
				// {
				// 	text: 're-instate job',
				// 	color: 'secondary',
				// 	serverCalls: {
				// 		reinstate: {
				// 			errorMessage: 'Could not re-instate job.',
				// 			directCall: (http, store, sq, _f, _cntrllr, _modal: ModalService) => {
				// 				_modal.openModalDirectly(instance => {
				// 					instance.type = 'warning';
				// 					instance.heading = 'Confirmation of Re-instated installation';
				// 					instance.message = 'Please confirm that you would like to re-instate this job.';
				// 					instance.navButtons = [
				// 						{
				// 							text: 'back',
				// 							linkType: 'close'
				// 						},
				// 						{
				// 							text: 'confirm',
				// 							color: 'secondary',
				// 							linkType: 'close',
				// 							clickHandler: ev => {
				// 								console.log('Clicking the motherfuckker');
				// 								return 0;
				// 							}
				// 						}
				// 					];
				// 				});
				// 				return of({});
				// 			}
				// 		}
				// 	}
				// },
				// {
				// 	text: 'initiate refund',
				// 	color: 'secondary',
				// 	serverCalls: {
				// 		initiate_refund: {
				// 			errorMessage: 'Could not initiate refund',
				// 			directCall: (_http, _store, _sq, _f, _cntrllr: ManifestController<any>) => {
				// 				return _store.select(getFullItemTwo).pipe(
				// 					filter(Boolean),
				// 					take(1),
				// 					map((result: any) => {
				// 						console.log('JOB RESULT', result);
				// 						const new_state: any = '';
				// 						const request = {
				// 							job_id: result?.id,
				// 							new_state: new_state
				// 						};
				// 						return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
				// 					})
				// 				);
				// 			}
				// 		}
				// 	},
				// 	nextNode: 'SubmissionSuccess'
				// }
			]
		},
		RecordReinstatementReason: {
			initFormFields: (_bf: BigFormService) => {
				_bf.addControl('assessment_reason', new UntypedFormControl('', [Validators.required]));
			},
			checkValidityForFields: ['assessment_reason'],
			hideTabItem: true,
			showTabs: false,
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							headingConfig: {
								weight: 'normal',
								size: 'medium'
							},
							title: 'Request Reason'
						}
					},
					// {
					// 	component: 'FLXHeadingWithInstructionsComponent',
					// 	inputs: {
					// 		headingConfig: {
					// 			weight: 'bold',
					// 			size: 'small',
					// 			color: 'secondary'
					// 		},
					// 		title: `Customer's Query`,
					// 		instructions: [`My unit has stopped working and I only had it installed yesterday`]
					// 	}
					// },

					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							// heading: 'Reason for Re-instated Installation',
							formControls: {
								0: {
									formControlName: 'assessment_reason',
									inputType: 'textarea',
									rows: 5,
									label:
										`Please enter the customer's query`								}
							},
							formLayout: 'stacked',
							containerWidth: '40vw'
						}
					}
				]
			},
			navs: [
				// {
				// 	text: 'invalid',
				// 	color: 'secondary',
				// 	// TODO: THIS WILL ACTUALLY HAVE TO ROUTE HANDLER TO SCREEN to APPOINT NEW SP
				// 	serverCalls: {
				// 		assign_new_sp: {
				// 			errorMessage: 'Could not assign new SP',
				// 			directCall: (_http: HttpClient, _store: Store) => {
				// 				return _store
				// 					.select(getSelectedItem)
				// 					.pipe(
				// 						filter(Boolean),
				// 						take(1)
				// 					)
				// 					// .pipe(
				// 					// 	switchMap(result => {
				// 					// 		const job_id = result?.id;
				// 					// 		const sp_id = 9999;
				// 					// 		// const sp_id = result?.sp?.id
				// 					// 		return _http.post(`${environment.api_url}v1/job_action/appoint_sp/`, { job_id: job_id, sp_id: sp_id });
				// 					// 	})
				// 					// )
				// 					.pipe(
				// 						map((serverResponse: any) => {
				// 							console.log('')
				// 							if (serverResponse) {
				// 								console.log('There is a server respon');
				// 								// return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
				// 							}
				// 						})
				// 					);

				// 				return of({});
				// 			}
				// 		}
				// 	}
				// },
				{
					text: 'invalid',
					color: 'secondary',
					// optIntoValidation: true,
					linkType: 'close',
					nextNode: 'SubmissionSuccess',
					// TODO: THIS WILL ACTUALLY HAVE TO ROUTE HANDLER TO SCREEN to APPOINT NEW SP
					serverCalls: {
						close_job: {
							errorMessage: 'Could not assign new SP',
							directCall: (_http: HttpClient, _store: Store) => {
								return _store
									.select(getSelectedItem)
									.pipe(
										filter(Boolean),
										take(1),
										switchMap((serverResponse: any) => {
											if (serverResponse) {
												const job_id = serverResponse?.id;
												const new_state = 28
												const request = {
													job_id: job_id,
													new_state: new_state
												}
												return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
											}
										})
									)

								return of({});
							}
						}
					}
				},
				{
					text: 'appoint original sp',
					color: 'primary',
					linkType: 'submit',
					optIntoValidation: true,
					serverCalls: {
						assign_original_sp: {
							errorMessage: 'Could not assign new SP',
							directCall: (_http: HttpClient, _store: Store) => {
								return _store
									.select(getSelectedItem)
									.pipe(
										filter(Boolean),
										take(1),
										switchMap((serverResponse: any) => {
											if (serverResponse) {
												const job_id = serverResponse?.id;
												const new_state = 370
												const request = {
													job_id: job_id,
													new_state: new_state
												}
												// console.log('There is a server respon', request);
												return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);
											}
										})
									)

								return of({});
							}
						}
					}
				},
				{
					text: 'appoint new sp',
					optIntoValidation: true,
					color: 'primary',
					linkType: 'submit',
					nextNode: 'SpReplyList',
				}
			]
		},
		SpReplyList: {
			initFormFields: (_bf: BigFormService) => {
				_bf.addControl('incentivised', new UntypedFormControl(false, []));
			},
			component: 'FLXSpReplyListComponent',
			inputs: {
				assignSpStateList: [369],
				selectSPNavigateToNode: 'AllocateSPSummary',
				subTitle: 'Please select a service provider below'
			},
			serverCalls: {
				interestedParties: {
					serviceVariable: 'pggService',
					functionName: 'getInterestedParties',
					responseSlice: 'payload',
					errorMessage: 'An error occurred getting the interested parties'
				},
				replyListData: {
					errorMessage: 'An error occurred getting the SP Data',
					serviceVariable: 'pggService',
					functionName: 'getSPReplyListData'
				}
			},
			navs: [
				{
					text: 'Reping SP Request',
					color: 'primary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any) => {
							instance.type = 'warning';
							instance.setMessage([
								'You are about to reping all the service providers that are able to do this job',
								'',
								'Please ensure you understand the implications of this decision'
							]);
							instance.navButtons = [
								{ text: 'Take me back', color: 'alert', linkType: 'close' },
								{
									text: 'I understand',
									color: 'alert',
									linkType: 'nextNode',
									nextNode: 'SetAppointmentForReping'
								}
							];
						}
					}
				}
			]
		},
		AllocateSPSummary: {
			serverCalls: {
			  title: {
				errorMessage: 'Could not get SP name',
				directCall: (http: any, store: any, sq: any, bf: any) => {
				  return of(bf.bigForm.get('sp_selected_item')?.value?.name);
				},
			  },
			  // title: {
			  // 	errorMessage: 'Could not get ',
			  // 	directCall: (_http: any, _store: any, sq: any, _bf: BigFormService) => {
			  // 		return _store.select(getSelectedItemTwo).pipe(
			  // 			filter(Boolean),
			  // 			take(1),
			  // 			mergeMap((item: any) => {
			  // 				const job = item;
			  // 				return forkJoin([
			  // 					_http.post(`${environment.api_url}v1/mc_actions/get_suitable_sps/`, { job_id: job?.id }).pipe(
			  // 						filter(Boolean),
			  // 						take(1),
			  // 						pluck('payload')
			  // 					),
			  // 					_store.select(getAllInfo).pipe(
			  // 						filter(Boolean),
			  // 						take(1),
			  // 						pluck('sps')
			  // 					)
			  // 				]).pipe(
			  // 					switchMap(([esp, sps]: any) => {
			  // 						console.log('vaule', _bf.bigForm.get('sp_selected_item')?.value);
			  // 						let spname;
			  // 						for (let i = 0; i < sps.length; i++) {
			  // 							if (sps[i].id === _bf.bigForm.get('sp_selected_item')?.value) {
			  // 								for (let j = 0; j < esp.length; j++) {
			  // 									if (_bf.bigForm.get('sp_selected_item')?.value === esp[j].sp) {
			  // 										spname = sps[i].name;
			  // 									}
			  // 								}
			  // 							}
			  // 						}
			  // 						return of(spname);
			  // 					})
			  // 				);
			  // 			})
			  // 		);
			  // 	}
			  // },
	  
			  spkeyvalues: {
				errorMessage: 'no info',
				directCall: (
				  _http: HttpClient,
				  _store: Store,
				  sq: any,
				  _bf: BigFormService
				) => {
				  return _store.select(getSelectedItemTwo).pipe(
					filter(Boolean),
					take(1),
					mergeMap((item: any) => {
					  const job: any = item;
					  return forkJoin([
						_store.select(getAllInfo).pipe(
						  filter(Boolean),
						  take(1),
						  map(response => response.sps)
						),
						_http
						  .post(
							`${environment.api_url}v1/job_action/get_interested_parties/`,
							{ job_id: job?.id }
						  )
						  .pipe(
							filter(Boolean),
							take(1),
							map((response: any) => response.payload)
						  ),
					  ]).pipe(
						switchMap(([spinfo, sps]: any) => {
						  let sp;
						  for (let i = 0; i < sps.length; i++) {
							if (sps[i].sp.id === _bf.bigForm.get('sp_selected_item')?.value.id) {
							  for (let j = 0; j < spinfo.length; j++) {
								if (
								  _bf.bigForm.get('sp_selected_item')?.value.id ===
								  spinfo[j].id
								) {
								  sp = spinfo[j];
								}
							  }
							}
						  }
						  const returnobj = {
							'Primary Contact': sp?.contact_person,
							'Primary Contact Number': sp?.contact_primary,
						  };
						  return [returnobj];
						})
					  );
					})
				  );
				},
			  },
			},
			component: {
			  children: [
				{
				  component: 'FLXKeyValueDisplayWithInstructionsComponent',
				  inputs: {
					title: 'Details for ',
					instructions: [
					  'Please double check the details of the selected service provider',
					],
				  },
				},
	  
				{
				  component: 'FLXKeyValueListComponent',
				  inputs: {
					data$: 'spkeyvalues',
				  },
				},
			  ],
			},
			navs: [
			  {
				disableOnFirstClick: true,
				text: 'allocate this sp',
				color: 'primary',
				nextNode: 'SubmissionSuccess',
				serverFirst: true,
				serverCalls: {
				  appointSP: {
					errorMessage:
					  'An error occurred while manually allocating a service provider',
					directCall: (http, store, sq, bf) => {
					  return forkJoin([
						store.select(getSelectedItemTwo).pipe(
						  filter(Boolean),
						  take(1)
						),
						of(bf.bigForm.get('sp_selected_item')?.value),
					  ]).pipe(
						switchMap(([selectedjob, selectedsp]: any) => {
							console.log('Selected JOB', selectedjob)
							console.log('Selected SP', selectedsp)
						  const job_id = selectedjob?.id;
						  return http.post(
							`${environment.api_url}v1/job_action/appoint_sp/`,
							{ job_id: job_id, sp_id: selectedsp?.id }
						  );
						// return of({})
						})
					  );
					},
				  },
				},
			  },
			],
		  },
		SetAppointmentForReping: {
			checkValidityForFields: ['appointmentData'],
			inputs: {
				minDate: new Date(),
				showExistingAppointment: true,
				minHour: 8,
				maxHour: 17
			},
			initFormFields: (bf: any, item: any, instance: any, sq: any, _store: Store) => {
				bf.addControl('claimtype_string', new FormControl(''));
				_store
					.select(getFullItemTwo)
					.pipe(
						filter(Boolean),
						take(1),
						map((data: any) => {
							const { claim } = data;
							const { source } = claim;
							const claimtypestring = source.replace(/^./, source[0].toUpperCase()) + ' Installation';
							bf.patchValues({ claimtype_string: claimtypestring });
						})
					)
					.subscribe()
					.unsubscribe();
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentTime: new UntypedFormControl(null, [Validators.required]),
						appointmentTimePicker: new UntypedFormControl(null, [Validators.required]),
						appointmentdatetype: new UntypedFormControl(null),
						skill: new UntypedFormControl(bf.getControl('claimtype_string').value)
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
				// });
			},
			serverCalls: {
				customer_details: {
					errorMessage: 'No customer contact details could be found!',
					directCall: (http: any, store: any, sq: any) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											applicant {
												first_name
												surname
												contact_number
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									filter(Boolean),
									take(1),
									map((res: any) => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [
										{ 'Client Name': `${queryData.first_name} ${queryData.surname}` },
										{ 'Contact Number': `${queryData.contact_number}` },
										{ 'Mobile Number': `${queryData.contact_number}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'No onsite details could be found!',
					directCall: (http: any, store: any, sq: any) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemOne {
											applicant {
												first_name
												contact_number
											}
										}
									}
								`,
								store.select(getFullItemOne).pipe(
									filter(Boolean),
									take(1),
									map((res: any) => ({ fullItemOne: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.first_name}` }, { 'Onsite Contact Number': `${queryData.contact_number}` }];
								})
							);
					}
				},
				existing_appointment: {
					errorMessage: `Couldn't get existing appointment details!`,
					directCall: (_tp: any, _st: any) => {
						return _st
							.select(getFullItemTwo)
							.pipe(
								filter(Boolean),
								take(1),
							)
							.pipe(
								map((fit: any) => {
									const appointment = fit?.appointment[0] ?? [];
									return [
										{
											'Original Appointment Date': `${humaniseDate(appointment.range_start)}` ?? 'not set',
											'Original Appointment Time': `${moment(appointment.range_start)?.format('HH:mm')}` ?? 'not set'
										}
									];
								})
							);
					}
				},
				appointmentTypes: {
					errorMessage: `Couldn't get appointment types!`,
					directCall: (_http: any, _store: any,) => {
						return _store
							.select(getAllInfo)
							.pipe(
								filter(Boolean),
								take(1),
								map((res: any) => res.appointment_types)
							)
					}
				}
			},
			component: 'FLXJobAppointmentComponent',

			navs: [
				{
					text: 'Continue',
					linkType: 'submit',
					serverFirst: true,
					serverCalls: {
						repingSP: {
							serviceVariable: 'pggService',
							functionName: 'repingSP',
							errorMessage: 'An error occurred while repinging the Service Provider'
						}
					},
					color: 'primary',
					nextNode: 'SubmissionSuccess'
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},

	bigFormToStoreMapper: {
		current_state: 'current_state',
		new_state: 'new_state',
		assessment_reason: 'job_information.assessment_reason',
		decline_reason: [
			(dr, storeObj, bf) => {
				let pd: any[] = [];
				if (
					storeObj &&
					storeObj['selectedContext'] &&
					storeObj['selectedContext']?.fullItemTwo?.job_information &&
					storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline
				) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline)) {
						pd = storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline;
					} else {
						pd = [{ ...(<object>storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline) }];
					}
				}

				const payment_decline = [...pd, { reason: dr, comment: bf.decline_notes }];
				return payment_decline;
			},
			'job_information.payment_decline'
		],

		decline_reason_string: [
			(dr, storeObj, bf) => {
				return bf.decline_reason ? getAllInfoIndex('cancel_reasons', 'id', 'name', bf.decline_reason, storeObj) : null;
			},
			'job_information.declinereason'
		]
	}
};
