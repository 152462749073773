/* eslint-disable @typescript-eslint/no-explicit-any */
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BigFormService, Flow_0_0_2, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { CustomValidators } from '@flexus/utilities';
import { setActionPanelItems, CollapseActionPanel } from '../../../../app-shell-features';
import gql from 'graphql-tag';
import { take, map, filter } from 'rxjs';
import { customerQueryNode } from '../reusable/CUSTOMER_REFUND_QUERY';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';

export const PGG_259: Flow_0_0_2 = {
	id: '259',
	name: 'request_voucher_cancellation',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['installation-summary', 'documents']),
	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				filter(Boolean),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Request voucher cancellation';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {},
	instructions: {
		editRoles: {
			0: 'Voucher cancellation request'
		},
		viewRoles: {
			0: 'Voucher cancellation request'
		}
	},
	startNode: 'RefundQueryInfo',
	nodes: {
		RefundQueryInfo: {
			...jobInfoNode(259, 'Refund Query'),
			navs: [
				{
					text: 'continue',
					linkType: 'portal',
					nextNode: 'RefundQueryCalculator'
				}
			]
		},
		RefundQueryCalculator: {
			initFormFields: (bf: any) => {
				bf.addControl(
					'amounts',
					new UntypedFormGroup({
						ins_takealot_fee: new UntypedFormControl('', []),
						ins_installer_fee: new UntypedFormControl('', []),
						ins_installer_incentive: new UntypedFormControl('', []),
						ins_installer_additional: new UntypedFormControl('', []),
						ins_sure_fee: new UntypedFormControl('', []),
						cus_takealot_fee: new UntypedFormControl('', [CustomValidators.numeric]),
						cus_installer_fee: new UntypedFormControl('', []),
						cus_installer_incentive: new UntypedFormControl('', []),
						cus_installer_additional: new UntypedFormControl('', []),
						cus_sure_fee: new UntypedFormControl('', [])
					})
				);
			},
			serverCalls: {
				refundVariablesFromSource: {
					errorMessage: `Couldn't get refund variables`,
					directCall: (http: any, store: any, sq: any) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													values {
														total_value
														client_value
														sp_value
													}
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									filter(Boolean),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((values: any) => {
									const voucheramount = values?.total_value;
									const sp_amount = values?.sp_value;
									const takealot_amount = values?.client_value;
									const refundVariableObj = {
										voucher_amount: voucheramount,
										sp_value: sp_amount,
										client_value: takealot_amount
									};
									return { refundVariableObj };
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'PinggoSPPaymentBreakdownComponent',
						inputs: {
							paymentVariables$: 'refundVariablesFromSource',
							state: 259
						}
					}
				]
			},
			navs: [
				{
					text: 'respond to  customer',
					color: 'primary',
					serverFirst: true,
					// serverCalls: {
					// 	response: { serviceVariable: 'pggService', functionName: 'updateJob', errorMessage: `Couldn't update job` }
					// },
					linkType: 'portal',

					nextNode: 'customerQuery'
				}
			]
		},
		customerQuery: {
			initFormFields: (_bf: BigFormService) => {
				_bf.patchValues({ new_state: 267 });
			},
			checkValidityForFields: [''],
			serverCalls: {},
			...customerQueryNode(259, '', [], '', 'Please enter your response to the customer.'),
			navs: [
				{
					text: 'submit',
					color: 'primary',
					serverFirst: true,
					serverCalls: {
						response: { serviceVariable: 'pggService', functionName: 'updateJob', errorMessage: `Couldn't update job` }
					}
				}
			]
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		customer_query: [
			(customer_query: any) => {
				return customer_query;
			},
			'job_information.customer_query'
		]
	}
};
