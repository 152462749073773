/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getFullItemTwo} from '@flexus/core';
import { filter, map, take } from 'rxjs';
import { forkJoin, of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

export const PGG_CANCEL_JOB: Flow_0_0_2 = {
	id: 'CancelJob',
	itemType: 'flow',
	// includeForOnlyStates: ['all'],
	name: 'Cancel Job',
	includeForOnlyStates: [77, 21, 22, 23],
	excludeForOnlyRoles: [18],
	header: {
		title: (store, bf) => {
			bf?.patchValues({ new_state: 258 });
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					// debugger;
					if (itemTwo) {
						return `Cancel Job : ${itemTwo?.claim?.loan_information?.voucher_key} - ${itemTwo?.claim?.applicant?.first_name}`;
					} else {
						return 'Cancel Job';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'CancelJobReason',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	actionPanel: () => {
		return () => {
			return [{}];
		};
	},
	serverCalls: {
		jobdata: {
			errorMessage: 'No job information returned',
			directCall: (http, store) => {
				return forkJoin([
					store.select(getFullItemTwo).pipe(
						filter(Boolean),
						take(1)
					),
					store.pipe(
						// skipWhile((stor) => !stor),
						filter(Boolean),
						take(1)
					)
				]).pipe(
					map(([job, stor]: any) => {
						return { job, stor };
					})
				);
			}
		}
	},
	nodes: {
		CancelJobReason: {
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				containerWidth: '50vw',
				heading: '',
				formControls: {
					0: {
						label: 'Select the reason for cancellation',
						inputType: 'select',
						selectConfig: {
							displayOptions: { displayKey: 'display', valueKey: 'value' },
							itemsOption: [
								{ display: 'Cancel', value: 'Cancel' },
								{ display: 'Cancel - Client Request', value: 'Cancel - Client Request' },
								{ display: 'Duplicate', value: 'Duplicate' },
								{ display: 'Other', value: 'Other' }
							],
							searchEnabled: false,
							placeholder: 'Reason for cancellation'
						},
						formControlName: 'cancelclaim_reason'
					}
				}
			},
			navs: [
				{
					text: 'Continue without refund',
					linkType: 'portal',
					color: 'secondary',
					portalData: {
						type: 'modal',
						paramFunc: instance => {
							instance.heading = 'Continue without refund?';
							instance.type = 'warning';
							instance.setMessage(['Are you sure you would like to cancel the job without refunding the customer?']);
							instance.navButtons = [
								{
									text: 'Back',
									linkType: 'close'
								},
								{
									text: 'Cancel without refund',
									color: 'alert',
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										cancelResponse: {
											serviceVariable: 'pggService',
											functionName: 'cancelJob',
											errorMessage: 'Job was not cancelled!',
											followUpFailCalls: {
												fail: {
													errorMessage: 'Could not display the fail!',
													directCall: (http, store, sq, bf, con, modal) => {
														modal.openModalDirectly(inst => {
															inst.type = 'warning';
															inst.message = 'Job was not cancelled!';
															inst.navButtons = [
																{
																	text: 'Back to workflow',
																	clickHandler: () => {
																		inst.router.navigate(['/workflow']);
																	},
																	linkType: 'close',
																	color: 'alert'
																}
															];
														});
														return of(true);
													}
												}
											}
										}
									}
								}
							];
						}
					}
				},
				{
					text: 'Continue with refund',
					color: 'secondary',
					nextNode: 'SubmissionSuccess',
					linkType: 'submit',
					serverFirst: true,
					serverCalls: {
						response: {
							serviceVariable: 'pggService',
							functionName: 'cancelJob',
							errorMessage: 'Job not cancelled',
							followUpSuccessCalls: {
								updateJob: {
									functionName: 'updateJobNewState',
									serviceVariable: 'mulService',
									errorMessage: 'unable to update job!'
								}
							}
						}
					}
				}
			],
			initFormFields: (bf) => {
				bf.addControl('cancelclaim_reason', new UntypedFormControl());
				bf.addControl('new_state', new UntypedFormControl());
			}
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
