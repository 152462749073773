/* eslint-disable @typescript-eslint/no-explicit-any */
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, of, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { getActiveManifestItem, ModalService, IndexedDbService, ManifestController, BigFormService } from '@flexus/core';

@Injectable({ providedIn: 'root' })
export class ActivateStateGuard implements OnDestroy, CanActivate {
	manifestItem;
	manifestItemSub: Subscription;
	authenticated$: Observable<boolean>;

	constructor(
		public router: Router,
		public store: Store<any>,
		public controller: ManifestController<any>,
		public modalService: ModalService,
		public bf: BigFormService,
		public indexedDbService: IndexedDbService
	) {
		this.manifestItemSub = this.controller
			.select(getActiveManifestItem)
			.pipe(take(1))
			.subscribe(item => (this.manifestItem = item));
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
		// Start activity if it exists even if nodes are not to be loaded
		if (this.manifestItem?.activity) {
			this.manifestItem?.activity(this, this.store);
		}
		if (this.manifestItem?.dontLoadNodes) {
			return of(false);
		} else {
			if (this.manifestItem?.activateStateGuard) {
				return this.manifestItem?.activateStateGuard(this, route, state);
			} else {
				return of(true);
			}
		}
	}

	ngOnDestroy() {
		if (this.manifestItemSub) this.manifestItemSub.unsubscribe();
	}
}
