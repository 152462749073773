/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigFormService, Flow_0_0_2, getFullItemOne } from '@flexus/core';
import { setActionPanelItems } from '../../../../app-shell-features';
import { take, map, filter} from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UntypedFormControl, Validators } from '@angular/forms';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';

export const PGG_382: Flow_0_0_2 = {
	id: '382',
	name: 'warranty_assistance_report',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				filter(Boolean),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Warranty Assistance Report';
					if (itemOne?.loan_information?.voucher_key) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Finalise Warranty Job'
		},
		viewRoles: {
			0: 'Report Overview'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'pggService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		
	},
	startNode: 'jobInformation',
	nodes: {
		jobInformation: {
            initFormFields: (_bf: BigFormService) => {
                _bf.addControl('new_state', new UntypedFormControl( 28, [Validators.required]));
            },
			showTabs: false,
			...jobInfoNode(382),
           
            navs: [
                { 
                    text: 'all work done',
                    color: 'primary',
                    nextNode: 'submissionSuccess',
                    serverCalls: {
                       response: {
                        errorMessage: 'Could not update job',
                        serviceVariable: 'pggService',
                        functionName: 'updateJob'
                       }
                    }
                }
                
            ]
		},
       
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
	}
};
