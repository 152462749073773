/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getFullItemOne, getSelectedItemOne } from '@flexus/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { take, map, filter } from 'rxjs';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const PGG_14: Flow_0_0_2 = {
	id: '14',
	name: 'what_matters_rating',
	itemType: 'flow',
	header: {
		title: store => {
			return store.select(getFullItemOne).pipe(
				filter(Boolean), // applicant of undefined error when going back to workflow
				take(1),
				map(itemOne => {
					if (itemOne) {
						return 'Customer Rating - ' + itemOne?.loan_information.voucher_key + ' - ' + itemOne?.applicant?.first_name;
					} else {
						return 'Customer Rating';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: (instance: any) =>
		setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], {
			filesFunctionName: 'getAllClaimFiles'
		}),
	instructions: {
		editRoles: {
			0: 'Rating'
		},
		viewRoles: {
			0: 'Rating'
		}
	},
	serverCalls: {
		skills: {
			serviceVariable: 'silService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills could be found.'
		},
		serviceProviders: {
			serviceVariable: 'silService',
			functionName: 'getServiceProvidersOnClaim',
			errorMessage: 'No service providers could be found.'
		}
	},
	startNode: 'CustomerRating',
	nodes: {
		CustomerRating: {
			component: 'CustomerRatingComponent',
			inputs: {
				organisationName: 'SIL Centre'
			},
			checkValidityForFields: ['customerrating', 'sp_ratings'],
			initFormFields: (bf: any, item: any, instance: any) => {
				bf.addControl('new_state', new UntypedFormControl('15'));
				instance.selectedItem$
					.pipe(
						filter(Boolean),
						take(1)
					)
					.subscribe((claim: any) => {
						bf.addControl('claim_id', new UntypedFormControl(claim?.id));
					});
				bf.bigForm.addControl('customerrating', new UntypedFormControl('', Validators.required));
				bf.bigForm.addControl('whatmattersachieved', new UntypedFormControl(false));
				bf.bigForm.addControl('failedwhatmattersreason', new UntypedFormControl('', Validators.required));
				const initSPForm = (serviceProviders: any[]): any[] => {
					return serviceProviders.map((sp: any) => {
						return new UntypedFormGroup({
							id: new UntypedFormControl(sp.jobId, Validators.required),
							customerrating: new UntypedFormControl('', Validators.required),
							whatmatters: new UntypedFormControl(false),
							whatmattersfailreason: new UntypedFormControl('', Validators.required)
						});
					});
				};
				instance.serviceProviders$
					.pipe(
						filter(Boolean),
						take(1)
					)
					.subscribe((sps: any) => {
						if (sps) {
							bf.bigForm.addControl('sp_ratings', new UntypedFormArray(initSPForm(sps)));
						}
					});
			},
			intersectData: (bf: any) => {
				bf.bigForm.removeControl('customerratingdone');
				bf.patchValues({ customerrating: bf.bigForm?.value?.customerrating });
				bf.patchValues({ failedwhatmattersreason: bf.bigForm?.value?.failedwhatmattersreason });
			},
			navs: [
				{
					text: 'Continue Without Rating',
					nextNode: 'SubmissionSuccess',
					color: 'alert',
					serverFirst: true,
					intersectData: (bf: any, store: any) => {
						bf.patchValues({
							sp_ratings: [],
							customerrating: null,
							whatmattersachieved: null,
							failedwhatmattersreason: null
						});
						bf.addControl('new_state', new UntypedFormControl('15'));
						store
							.select(getSelectedItemOne)
							.pipe(
								filter(Boolean),
								take(1)
							)
							.subscribe((claim: any) => {
								bf.addControl('claim_id', new UntypedFormControl(claim?.id));
							});
						bf.addControl('customerratingdone', new UntypedFormControl('0'));
					},
					serverCalls: {
						response: {
							errorMessage: 'This claim could not be created!',
							serviceVariable: 'silService',
							functionName: 'updateClaim'
						}
					}
				},
				{
					text: 'Continue',
					nextNode: 'WhatMatters',
					optIntoValidation: true,
					color: 'primary'
				}
			]
		},
		WhatMatters: {
			component: 'FLXFlatDynamicFormComponent',
			inputs: {
				formControls: {
					0: {
						label: 'What did we do well?',
						inputType: 'textarea',
						rows: 5,
						width: '48%',
						formControlName: 'how_we_did'
					},
					1: {
						label: 'What can we improve on?',
						inputType: 'textarea',
						rows: 5,
						width: '48%',
						formControlName: 'how_can_do'
					}
				},
				formLayout: 'stacked',
				containerWidth: '50vw'
			},
			checkValidityForFields: ['how_we_did', 'how_can_do'],
			initFormFields: (bf: any) => {
				bf.addControl('how_we_did', new UntypedFormControl('', Validators.required));
				bf.addControl('how_can_do', new UntypedFormControl('', Validators.required));
			},
			navs: [
				{
					text: 'Submit',
					linkType: 'submit',
					color: 'primary',
					optIntoValidation: true,
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'This claim could not be created!',
							serviceVariable: 'silService',
							functionName: 'updateClaim'
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		claim_id: 'claim_id',
		sp_ratings: [
			(sp_ratings: any) => {
				return sp_ratings
					? sp_ratings.map((obj: any) => {
							const answer = obj.whatmatters === true ? '1' : '0';
							return {
								...obj,
								customerrating: obj.customerrating?.toString(),
								whatmatters: answer
							};
					  })
					: null;
			},
			'sp_ratings'
		],
		customerrating: 'loan_information.customerrating',
		whatmattersachieved: [
			(val: any) => {
				return val === true ? '1' : '0';
			},
			'loan_information.whatmattersachieved'
		],
		failedwhatmattersreason: 'loan_information.failedwhatmattersreason',
		customerratingdone: 'loan_information.customerratingdone',
		how_we_did: 'loan_information.ratingwhatwedidwell',
		how_can_do: 'loan_information.ratingwecanimprove'
	}
};
