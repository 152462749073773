/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { UntypedFormControl} from '@angular/forms';
import { BigFormService, Flow_0_0_2, getFullItemOne, getSelectedItemOne, getSelectedItemTwo } from '@flexus/core';
import { Store } from '@ngrx/store';
import { setActionPanelItems, CollapseActionPanel } from '../../../../app-shell-features';
import { take, map, mergeMap, switchMap, filter } from 'rxjs';
import { PGG_BANK_DETAILS } from '.';
import { environment } from 'apps/studio/src/environments/environment';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';

export const PGG_284: Flow_0_0_2 = {
	id: '284',
	name: 'payment_failed',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['installation-summary', 'documents']),
	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				filter(Boolean),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Payment Failed';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {},
	instructions: {
		editRoles: {
			0: 'Payment failed'
		},
		viewRoles: {
			0: 'Payment failed'
		}
	},
	startNode: 'RefundFailedNotification',
	nodes: {
		RefundFailedNotification: {
			initFormFields: (bf: any) => {
				bf.addControl('new_state', new UntypedFormControl(28, []));
			},
			...jobInfoNode(284, 'Payment Failed', ['Contact installer to confirm banking details']),

			navs: [
				{
					text: 'payment made',
					color: 'secondary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any) => {
							instance.type = 'warning';
							instance.heading = 'Have you paid the installer';
							instance.setMessage(['Clicking YES will move the job to a closed state.']);
							instance.navButtons = [
								{
									text: 'no',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'yes',
									color: 'default',
									linkType: 'submitThenNext',
									nextNode: 'SubmissionSuccess',
									serverCalls: {
										response: {
											serviceVariable: 'pggService',
											functionName: 'updateJob',
											errorMessage: `Job couldn't be updtated`
										}
									}
								}
							];
						}
					}
				},
				{
					text: 're-enter banking details',
					color: 'primary',
					linkType: 'portal',
					nextNode: 'BankDetailsCapture'
				}
			]
		},
		BankDetailsCapture: {
			initFormFields: (bf: any) => {
				bf.patchValues({ new_state: 283 }); // find correct  state here
			},
			component: 'PggBankDetailsTemplateComponent',
			...PGG_BANK_DETAILS,
			navs: [
				{
					text: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: '',
							directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService) => {
								_bf.patchValues({ new_state: 283 });
								return _store.select(getSelectedItemOne).pipe(
									filter(Boolean),
									take(1),
									mergeMap((data: any) => {
										const claim = data;
										const { id } = claim;
										const formcontrol = _bf.getControl('bank_details');
										const account_holder_name = formcontrol.get('account_holder_name').value;
										const account_number = formcontrol.get('account_number').value;
										const account_type = formcontrol.get('account_type').value.toLowerCase();
										const bank_name = formcontrol.get('bank_name').value;
										return _http.post(`${environment.api_url}v1/refund_action/modify_bank_details/`, {
											application_id: id,
											account_holder: account_holder_name,
											account_type: account_type,
											bank_name: bank_name,
											account_number: account_number
										});
									})
								);
							},
							followUpSuccessCalls: {
								updateJob: {
									errorMessage: 'Could not update job!',
									directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService) => {
										return _store.select(getSelectedItemTwo).pipe(
											filter(Boolean),
											take(1),
											switchMap((job: any) => {
												const job_id = job?.id;
												const new_state = _bf.getControl('new_state').value;
												return _http.post(`${environment.api_url}v1/job_action/update_job/`, { job_id: job_id, new_state: new_state });
											})
										);
									}
								}
							}
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
