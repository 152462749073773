/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import {  getSelectedItem, MakeServerCall, ModalService, NodePoint_0_0_2 } from '@flexus/core';
import { Store } from '@ngrx/store';
import { of, take, map, switchMap, filter } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';

export const photoNode = (state?, new_state?): NodePoint_0_0_2 => {
	return {
		name: 'Photos',
		initFormFields: (bf) => {
			if (new_state) {
				bf.patchValues({ new_state });
			}
		},
		component: 'FLXViewPhotosComponent',
		inputs: {
			heading: 'Job Photos',
			location: 'context-menu'
		},
		showTabs: true,
		navs: [
			...(state === 372
				? [
                    {
                        text: 'invalid warranty',
                        color: 'secondary',
                        serverCalls: {
                            invalidate_warrannty: {
                                errorMessage: '',
                                directCall: (h, _store,sq, bf, cntrl, _modal: ModalService) => {
                                    let customer_name = '';
                                    let customer_number = '';
                                    _store.select(getSelectedItem).pipe(
                                        filter(Boolean),
                                        take(1),
                                        map((res: any) => {
                                            customer_name = res?.claim?.applicant?.first_name;
                                            customer_number = res?.claim?.applicant?.cellnumber ? res?.claim?.applicant?.cellnumber : 'No contact number in that place';
                                            console.log('RESIDUALE', res)
                                        }),
                                    ).subscribe()
                                    _modal.openModalDirectly(instance => {
                                        instance.type = 'warning';
                                        instance.heading = 'Please notifiy the customer the claim is invalid';
                                        instance.message = 'Customer Details'
                                        instance.setMessage([`Customer Name: ${customer_name}`, `Customer Number: ${customer_number}`]);
                                        instance.navButtons = [
                                            {
                                                text: 'I have informed the customer',
                                                color: 'default',
                                                clickHandler: () => {
                                                    _store.dispatch(
                                                        new MakeServerCall({
                                                          errorMessage: '',
                                                          directCall: (http: HttpClient, store: Store) => {
                                                            store.select(getSelectedItem).pipe(
                                                                filter(Boolean),
                                                                take(1),
                                                                switchMap((res: any) => {
                                                                    const requestdata = {
                                                                        job_id: res.id,
                                                                        new_state: 378,
                                                                    }
    
                                                                    return http.post(`${environment.api_url}v1/job_action/update_job/`, requestdata);
                                                                })
                                                            ).subscribe()
                                                            // use success in 'update_job' piped for err?
                                                            // controller.dispatch(new SetNextNode('submissionSuccess'));
                                                            return of({});
                                                          }
                                                        })
                                                      );
                                                    
                                                }
                                            }
                                        ]
                                    })
                                    return of({})
                                }
                            }
                        }
                    },
                    {
                        text: 'approve warranty',
                        color: 'primary',
                        serverCalls: {
                            updateJob: {
                                errorMessage:'could not update job',
                                serviceVariable: 'pggService',
                                functionName: 'updateJob',
    
                            }
                        }
    
    
                    }
				  ]
				
				: [
						{text: 'no state'}
				  ])
		]
	};
};