/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getCurrentUser, SetNextNode, MakeServerCall, getSelectedItemOne, getFullItemTwo, getAllInfo, BigFormService } from '@flexus/core';
import { Validators, UntypedFormControl } from '@angular/forms';
import { of, forkJoin, EMPTY, map, switchMap, take, filter} from 'rxjs';
import gql from 'graphql-tag';
import { values } from 'ramda';
import { environment } from 'apps/studio/src/environments/environment';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { findJobLog } from '../reusable/state-utils';
import { getAllInfoIndex } from '@flexus/utilities';

export const PGG_312: Flow_0_0_2 = {
	id: '312',
	name: 'payment_approval_1',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store) => {
			return store.select(getFullItemTwo).pipe(
				filter(Boolean),
				take(1),
				map((itemTwo: any) => {
					let headerStr = 'Payment Approval 1';
					if (itemTwo) {
						headerStr += ` : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
					}
					return headerStr;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	serverCalls: {
		authorisers: {
			serviceVariable: 'pggService',
			functionName: 'getAuthorizers',
			errorMessage: 'No authorizers could be found!'
		},

		dataFiles: {
			serviceVariable: 'pggService',
			functionName: 'getJobInvoice',
			errorMessage: 'Could not get files from server!'
		},

		timeStamps: {
			errorMessage: 'Could not get time stamps',
			directCall: (http, store) => {
				return forkJoin([
					store.select(getSelectedItemOne).pipe(
						filter(Boolean),
						take(1)
					),
					store.select(getFullItemTwo).pipe(
						filter(Boolean),
						take(1)
					)
				]).pipe(
					filter(Boolean),
					switchMap(([claim, job]: any) => {
						return http.post(`${environment.api_url}v1/claim_action/get_time_logs/`, { [`claim_id`]: claim?.id }).pipe(
							filter(Boolean),
							switchMap((res: any) => {
								const setsOfJobLogs: any[] = res?.payload['jobs'];
								const autoPayJobLogForState27Or39 = findJobLog(setsOfJobLogs, job, [27, 39]);
								const applicationCreatorId = claim?.application_creator?.id;
								const paymentapproval1 = job?.job_information?.paymentapproval1;
								return http
									.post(environment.api_url + 'v1/staff_action/get_staff_member_names', {
										ids: [+applicationCreatorId, +paymentapproval1]
									})
									.pipe(
										map((data: any) => data?.payload),
										map((mapper: any) => ({ payArroval1: autoPayJobLogForState27Or39?.modifier || mapper[paymentapproval1], payHandler: mapper[applicationCreatorId] }))
									);
							})
						);
					})
				);
			}
		}
	},

	events: {
		invalidateDeclineDataIfNextApprovalStaffName: {
			triggerOn: 'nextApprovalStaffName',
			triggerWhen: nextApprovalStaffName => !!nextApprovalStaffName,
			dataMutations: bf => {
				bf.patchValues({
					decline_reason: null,
					new_state: 51
				});
			}
		},

		invalidatePaymentDataIfDeclineIsSet: {
			triggerOn: 'decline_reason',
			triggerWhen: qr => !!qr,
			dataMutations: bf => {
				bf.patchValues({
					nextApprovalStaffName: null,
					invoice_number: null,
					new_state: 52
				});
			}
		}
	},

	instructions: {
		editRoles: {
			0: 'Authorise the payment'
		},
		viewRoles: {
			0: '--'
		}
	},

	startNode: 'Decision',

	nodes: {
		Decision: {
			nodeType: 'decision',
			errorHandler: {
				displayFormat: 'dialog',
				retryPolicy: 'manual',
				onRetryComplete: () => {
					return EMPTY;
				}
			},
			decisions: {
				authorization: (navs, store, modal) => {
					return forkJoin([
						store.select(getFullItemTwo).pipe(
							filter(Boolean),
							take(1)
						),
						store.select(getCurrentUser).pipe(
							filter(Boolean),
							take(1)
						)
					])
						.pipe(
							switchMap(([job, user]: any) => {
								switch (true) {
									case parseInt(job.job_creator, 10) === user?.user?.id: {
										modal.openModalDirectly(instance => {
											instance.type = 'warning';
											instance.message = 'Job creator cannot provide second and third eyes for payment authorization';
											instance.navButtons = [
												{
													text: 'Back to workflow',
													clickHandler: () => {
														instance.router.navigate(['/workflow']);
													},
													linkType: 'close',
													color: 'alert'
												}
											];
										});
										break;
									}
									case parseInt(job?.job_information?.paymentapproval1, 10) === user?.user?.id: {
										modal.openModalDirectly(instance => {
											instance.type = 'warning';
											instance.message = 'You can not process a job which you initiated payment for';
											instance.navButtons = [
												{
													text: 'Back to workflow',
													clickHandler: () => {
														instance.router.navigate(['/workflow']);
													},
													linkType: 'close',
													color: 'alert'
												}
											];
										});
										break;
									}
									default: {
										store.dispatch(
											new MakeServerCall({
												dataKey: 'updateJob',
												serviceVariable: '',
												functionName: '',
												errorMessage: 'Could not update Job',
												directCall: (http, store, sq, bf, controller) => {
													bf.patchValues({ paymentapproval2: user?.user?.id });
													controller.dispatch(new SetNextNode('PaymentPreview'));
													return of({});
												}
											})
										);

										break;
									}
								}

								return 'Decision';
							})
						)
						.pipe(take(1))
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						.subscribe(nextNode => {});
				}
			},
			navs: [{ text: 'Success', nextNode: 'SubmissionSuccess' }]
		},

		PaymentPreview: {
			initFormFields: (_bf: BigFormService) => {
				_bf.patchValues({ new_state: 226 });
			},
			component: {
				children: [
					{
						component: 'FLXFileViewWithSideExtrasComponent',
						inputs: { keyValueList$: 'keyValueList', dataFiles$: 'dataFiles', inputHeading: 'Payment Preview' }
					}
				]
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'Something went wrong with claim information!',
					directCall: (http, store, sq) => {
						const invoice$ = sq
							.queryStore(
								gql`
									{
										selectedContext {
											dataFiles
										}
									}
								`
							)
							.pipe(
								filter(x => !!x && Object.keys(x).length !== 0),
								take(1)
							);
						return forkJoin([
							store.select(getSelectedItemOne).pipe(
								filter(Boolean),
								take(1)
							),
							store.select(getFullItemTwo).pipe(
								filter(Boolean),
								take(1)
							),
							store.select(getAllInfo).pipe(
								filter(Boolean),
								take(1)
							),
							invoice$.pipe(
								filter(Boolean),
								// pluck('dataFiles'),
								take(1),
								map(data => data.dataFiles)
							),
							sq
								.queryStore(
									gql`
										{
											selectedContext {
												timeStamps
											}
										}
									`
								)
								.pipe(
									filter(Boolean),
									take(1),
									map(response => response.timeStamps)
								)
						]).pipe(
							take(1),
							map(([claim, job, allInfo, invoice, timeStamps]: any) => {
								const list = {
									Claimant: claim?.applicant ? `${claim?.applicant?.first_name} ${claim?.applicant?.surname}` : '',
									'Claim ID': claim?.id ?? '',
									'Claim handler': claim?.application_creator?.full_name ?? '',
									Address: job?.address ?? 'No address found',
									// EXCESS NOT RELEVANT FOR INSTALLATION (NON-INSURANCE) CLAIM TYPES
									// 'Excess Amount': job?.job_information?.update_excess
									// 	? `R${job?.job_information?.update_excess}`
									// 	: job?.excess?.length !== 0
									// 	? `R ${job.excess[0]?.amount}`
									// 	: 'No excess found',
									// 'Collected by': job?.office_use?.excess?.who_collects_excess_desc ?? '',
									// 'Payment method': job?.office_use?.excess?.excess_payment_method_desc ?? '',
									// 'Excess state': ((): string => {
									// 	let state = '';
									// 	if (job.excess[0]) {
									// 		allInfo['excess_states']?.forEach(element => {
									// 			if (element.id === job?.excess[0]?.state) {
									// 				state = element.name;
									// 			}
									// 		});
									// 	}
									// 	return state;
									// })(),
									'Amount requested': job?.claim_value ? `R ${job.claim_value}` : invoice?.balance_due ? `R ${invoice.balance_due.toFixed(2)}` : 0,
									'Invoice number': invoice?.invoice_number ?? invoice?.invoice_number ? invoice?.invoice_number : '',
									'Job Status': ((): string => {
										let job_status = '';
										if (allInfo) {
											allInfo['valid_job']?.forEach(element => {
												if (element?.id === job?.valid_job) {
													job_status = element?.name;
												}
											});
										}
										return job_status;
									})(),
									'Pay type': ((): string => {
										let pay_type = '';
										if (allInfo) {
											allInfo['supplier_type']?.forEach(element => {
												if (element.id === job?.supplier_type) {
													pay_type = element?.name;
												}
											});
										}
										return pay_type;
									})(),
									'Maven Claim Number': claim ? claim?.mid : ''
								};
								const approvals = timeStamps?.payApproval2
									? {
											job_creator: timeStamps?.payHandler ? timeStamps?.payHandler : '',
											payment_approval_handler: timeStamps?.payArroval1 ? timeStamps?.payArroval1 : '',
											first_authoriser: timeStamps?.payApproval2 ? timeStamps?.payApproval2 : ''
									  }
									: {
											job_creator: timeStamps?.payHandler ? timeStamps?.payHandler : '',
											payment_approval_handler: timeStamps?.payArroval1 ? timeStamps?.payArroval1 : ''
									  };

								return [list, approvals];
							})
						);
					}
				}
			},
			navs: [
				{ text: 'Decline Payment', nextNode: 'DeclinePayment', color: 'alert' },
				{
					text: 'Approve Payment',
					color: 'primary',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						response: {
							errorMessage: 'Could not update job',
							serviceVariable: 'pggService',
							functionName: 'updateJob'
						}
					}
				}
			]
		},

		DeclinePayment: {
			component: 'FLXFlatDynamicFormComponent',
			serverCalls: {
				selectOptions: {
					errorMessage: 'Cannot find decline reasons',
					directCall: (http, stroe, sq) => {
						return sq
							.queryStore(
								gql`
									{
										allInfo {
											cancel_reasons
										}
									}
								`
							)
							.pipe(
								filter(x => !!x && Object.keys(x).length !== 0),
								take(1),
								map(values),
								map((value: any) => value[0]),
								map(vals => ({ declineReasons: vals }))
							);
					}
				}
			},
			inputs: {
				heading: '',
				formControls: {
					0: {
						label: 'Select reason for declining payment',
						inputType: 'select',
						selectConfig: {
							displayOptions: { displayKey: 'name', valueKey: 'id' },
							itemsOption: 'declineReasons',
							searchEnabled: false,
							placeHolder: 'Decline Reason'
						},
						formControlName: 'decline_reason'
					},
					1: {
						label: 'Enter note on the reason for declining',
						inputType: 'textarea',
						width: '48%',
						formControlName: 'decline_notes'
					}
				},
				containerWidth: '300px'
			},

			checkValidityForFields: ['decline_notes'],

			initFormFields: (bf, item, instance, sq) => {
				bf.patchValues({ decline_notes: '', new_state: 52 });
				bf.bigForm.get('decline_notes')?.setValidators([Validators.required, Validators.minLength(3)]);
				bf.bigForm.addControl('decline_reason_string', new UntypedFormControl(''));
				sq.queryStore(
					gql`
						{
							selectedContext {
								fullItemTwo {
									state
								}
							}
						}
					`
				)
					.pipe(
						filter(x => !!x && Object.keys(x).length !== 0),
						take(1)
					)
					.subscribe(current_state => {
						bf.patchValues({ current_state: current_state.state });
						// bf.addControl('current_state', new FormControl(current_state.state));
					});
			},

			navs: [
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					optIntoValidation: true,
					color: 'primary',
					serverCalls: {
						response: {
							serviceVariable: 'silService',
							functionName: 'updateJob',
							errorMessage: 'Job could not be updated!!'
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},

	bigFormToStoreMapper: {
		nextApprovalStaffName: [
			[
				aid => {
					const id = aid && Array.isArray(aid) ? parseInt(aid[0], 10) : null;
					return id;
				},
				'authid'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return null;
				},
				'job_information.nextApprovalStaffName'
			],
			[
				aid => {
					if (aid) {
						let id;
						aid = aid && Array.isArray(aid) ? aid : [];
						for (const auth of aid) {
							id = parseInt(auth, 10);
						}
						return id;
					}
					return null;
				},
				'job_information.authid'
			]
		],
		paymentapproval2: 'job_information.paymentapproval2',
		current_state: 'current_state',
		new_state: 'new_state',
		invoice_number: ['invoice_number', 'job_information.invoice_number'],
		decline_reason: [
			(dr, storeObj, bf) => {
				let pd: any[] = [];
				if (
					storeObj &&
					storeObj['selectedContext'] &&
					storeObj['selectedContext']?.fullItemTwo?.job_information &&
					storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline
				) {
					if (Array.isArray(storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline)) {
						pd = storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline;
					} else {
						pd = [{ ...(<object>storeObj['selectedContext']?.fullItemTwo?.job_information?.payment_decline) }];
					}
				}

				const payment_decline = [...pd, { reason: dr, comment: bf.decline_notes }];
				return payment_decline;
			},
			'job_information.payment_decline'
		],

		decline_reason_string: [
			(dr, storeObj, bf) => {
				return bf.decline_reason ? getAllInfoIndex('cancel_reasons', 'id', 'name', bf.decline_reason, storeObj) : null;
			},
			'job_information.declinereason'
		],
		decline_notes: ['job_information.declinereasoncomments']
	}
};
