/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nx/enforce-module-boundaries */
import { UntypedFormControl } from '@angular/forms';
import { Flow_0_0_2, getFullItemOne, getFullItemTwo } from '@flexus/core';
import { setActionPanelItems } from 'apps/studio/src/app/app-shell-features';
import gql from 'graphql-tag';
import { filter,  map, take } from 'rxjs';

export const PGG_140: Flow_0_0_2 = {
	id: '140',
	name: 'stock-damaged-notify-customer',
	itemType: 'flow',
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				filter(Boolean),
				take(1),
				map((itemOne: any) => {
					if (itemOne) {
						return `Stock Damaged - Notify Customer' : ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					} else {
						return 'Stock Damaged - Notify Customer';
					}
				}) 
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents', 'sp-details']),
	serverCalls: {
		files: {
			serviceVariable: 'pggService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		notes: {
			serviceVariable: 'pggService',
			functionName: 'getNotes',
			responseSlice: 'payload',
			errorMessage: 'No claim notes could be found!'
		}
	},
	instructions: {
		editRoles: {
			0: 'Stock Damaged - Appointment changes'
		},
		viewRoles: {
			0: 'Notify Customer of Damaged Stock'
		}
	},
	startNode: 'NotifyCustomer',
	nodes: {		
		NotifyCustomer: {
			initFormFields: (bf: any) => {
				bf.addControl('new_state', new UntypedFormControl(199));
			},
			serverCalls: {
				keyvaluelist: {
					errorMessage: 'No information found!',
				
					directCall: (http: any, store: any, sq: any) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												type
												applicant {
													first_name
													surname
													contact_number
												}
												loan_information {
													whatmatters
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									filter(Boolean),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								filter(x => !!x && Object.keys(x)?.length !== 0),
								take(1),
								map((data: any) => {
									console.log('DTADTAT', data)
									const list: any [] = []
									const keyvalueObject = {
										'Claim Type' : `${data.type}`,
										'Customer Name': `${data.first_name} ${data.surname}`,
										'Mobile	': `${data.contact_number}`,
										'What Matters': `${data.whatmatters}`	
									}
									list.push(keyvalueObject)
									return list
									// return data['data'];
								})
							);
					}
				},
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
									title: 'Notify Customer - Damaged Stock',
									headingConfig: {
										size: 'default',
										color: 'default',
										itemMargin: '35px 0 15px 0'
												},
												
								},
				},
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
									title: 'The SP has noted that the stock is damaged when collected',
									headingConfig: {
										size: 'small',
										color: 'default',
										itemMargin: '10px 0 0px 0'
												}
								},
					},
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
									title: 'Notify the customer the appointment will be missed.',
									headingConfig: {
										size: 'small',
										color: 'default',
										itemMargin: '0px 0 15px 0'
												}
								},
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {	
									// heading:  'Customer details',
									data$: 'keyvaluelist',
									
								},
					},

				]
			},
		navs: [
			{
				text: 'Customer Notified',
				nextNode: 'SubmissionSuccess',
				color: 'primary',
				serverCalls: {
					updateJob: {
						serviceVariable: 'pggService',
						functionName: 'updateJob',
						errorMessage: 'Could not update job!'	
					}
				}
			}
		]
	},
	SubmissionSuccess: {
		component: 'FLXSuccessTickComponent'
	}
			},
			
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};